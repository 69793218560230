* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 0;
    /* list-style-type: none !important;*/
}

.clear {
    clear: both;
}

body {
    margin: 0px;
    font-size: 16px;
    line-height: 23px;
    color: #fff;
    background: #000000;
    font-family: 'Montserrat', sans-serif;
}

.cursor-pointer {
    cursor: pointer
}

html,
body {
    width: 100%;
}

button.table-btn img {
    filter: brightness(0) invert(1);
}

button.table-btn {
    display: inline-flex;
    margin: 0 5px;
    background: #fbaf5d;
    border: 1px solid #fbaf5d;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
}

button.delete-btn {
    display: inline-flex;
    margin: 0 5px;
    background: #fbaf5d;
    border: 1px solid #fbaf5d;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
}

button.delete-btn img {
    filter: brightness(0);
}

.row-gap-5 {
    row-gap: 5px;
}

button.group-btn {
    display: inline-flex;
    margin: 0 5px;
    background: #fbaf5d;
    border: 1px solid #ffffff;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
}

.group-btn-primary {
    display: inline-flex;
    margin: 0 5px;
    background: #905738;
    border: 1px solid #905738;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
}

.color-btn img {
    filter: brightness(0) invert(1);
    height: 20px;
}

.color-btn {
    cursor: pointer;
    display: inline-flex;
    margin: 0 5px;
    background: transparent;
    border: none;
    width: auto;
    height: auto;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
}

a:hover {
    text-decoration: none;
}

a:focus {
    outline: none !important;
}

a {
    text-decoration: none;
    transition: all 0.2s ease-out;
    color: #fff;
}

img {
    max-width: 100%;
}

ul {
    margin: 0px;
    padding: 0px;
}

/* .container{
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  padding: 0px 15px;
} */
.headings_2 {
    margin-bottom: 30px;
}

.contact_section {
    padding-top: 52px;
    position: relative;
}

.footer-section {
    font-size: 14px;
}

.contact_inner {
    padding: 30px 0px 80px;
    position: relative;
}

.contactus_inner {
    padding: 30px 0px 80px;
    position: relative;
}

/*.contact_inner::after {
    content: '';
    position: absolute;
    width: 450px;
    right: -46px;
    top: 0;
    bottom: 0;
    background: rgba(211, 149, 79, 0.15);
}*/

.contact_inner,
.forgot_pass {
    width: 400px;
    margin: 0 auto;
}

.left_form h2,
.left_form p {
    color: #ffffff;
}

.form-check {
    color: #ffffff;
}

.form-control,
.form-select {
    background-color: rgba(211, 149, 79, 0.15);
    border: 1px solid rgba(211, 149, 79, 0.2);
    border-radius: 8px;
    padding: 6px 15px;
    width: 100%;
    min-height: 42px;
    font-size: 14px;
}

label {
    font-size: 14px;
}

.form-control:focus {
    background-color: rgba(211, 149, 79, 0.15);
    box-shadow: 0 0 10px rgba(208, 161, 109, 0.3);
    border: 1px solid rgba(211, 149, 79, 0.3);
}

.form-control:focus {
    color: #495057 !important;
    background: #fff !important;
    border: none;
    box-shadow: none;
}

.btn.btn-secondary {
    background-color: #FBAF5D;
    color: #ffffff;
    text-align: center;
    padding: 10px;
    min-height: 42px;
    border-radius: 8px;
    width: 100%;
    display: inline-block;
}

.btn.btn-secondary:hover,
.btn.btn-secondary:focus {
    background-color: #D3954F !important;
}

#logbtn {
    background-color: #FBAF5D;
    color: #ffffff;
    text-align: center;
    padding: 10px;
    min-height: 42px;
    border-radius: 8px;
    width: 100%;
    display: inline-block;
}

.right_contact {
    background: #fff;
    border-radius: 20px 20px 20px 0px;
    max-width: 500px;
    margin-left: auto;
    position: relative;
    z-index: 99;
}

.right_contact::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -56px;
    z-index: -1;
    width: 0;
    height: 0;
    border-left: 96px solid transparent;
    border-right: 0 solid transparent;
    border-top: 56px solid #d8d8d8;
}

.contact_list {
    padding: 40px 45px 10px 45px;
}

.contact_list li {
    display: flex;
    margin-bottom: 20px;
    color: #000000;
}

span.con_icon {
    background: rgba(208, 161, 109, 0.2);
    padding: 10px;
    border-radius: 5px;
    min-height: 60px;
    min-width: 60px;
    text-align: center;
    line-height: 40px;
    margin-right: 20px;
    display: inline-block;
    width: 60px;
    height: 60px;
}

span.con_detail>b {
    display: block;
}

span.con_detail {
    width: calc(100% - 80px);
}

ul.social_icons {
    display: flex;
    align-items: center;
    gap: 0 20px;
    border-top: 1px solid #ddd;
    padding: 20px 45px;
}

.Software_Modules_section {
    position: relative;
    color: #ffffff;
}

.Software_Modules_inner {
    padding: 30px 0px 80px;
    position: relative;
}

.d-flex {
    display: flex;
}

.box_outer {
    max-width: 750px;
    margin: 50px auto 0px;
}

.col-sm-6 {
    padding: 0 15px;
}

.box_inner {
    background-color: rgba(208, 161, 109, 0.3);
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    background-image: url(../src/assets/images/map.svg);
    border-bottom: 2px solid #FBAF5D;
    display: flex;
    flex-direction: column;
    align-items: start;
}

p.description {
    text-align: start;
}

.card-bottom {
    width: 100%;
}

.card-bottom ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    list-style-type: none;
    width: 100%;
}

.card-bottom ul li {
    display: flex;
    flex-wrap: wrap;
    gap: 9px;
    list-style-type: none;
}

li.sitename {
    margin-left: auto;
    color: black;
}

.box_inner p {
    font-weight: 600;
    min-height: 70px;
}

.icon-24px {
    width: 24px;
}

.brand-logo .logo_str {
    font-size: 64px !important;
}

.logo_str {
    margin-bottom: 20px;
    font-family: 'Barlow', sans-serif;
    font-size: 26px;
    /*  color: #FBAF5D;*/
    font-weight: normal;
    letter-spacing: 1px;
    line-height: normal;
    display: inline-block;
}

.logo_str:hover,
.logo_str:focus {
    color: #ffffff;
}

.logo_str b {
    font-weight: bold;
    color: #FBAF5D;
    font-style: italic;
}

.logo_str>span {
    color: #FBAF5D;
}

.headings_3 {
    text-align: center;
}

.headings_3 h3 {
    color: #D3954F;
    font-weight: normal;
    font-size: 30px;
    margin-bottom: 0;
    line-height: normal;
}

.headings_3>h2 {
    font-size: 40px;
    font-family: 'Barlow', sans-serif;
    font-weight: normal;
    line-height: normal;
}

.headings_3 .logo_wh {
    font-style: italic;
    letter-spacing: 1px;
}

/*.top_bar_outer {
    background: #FBAF5D;
}*/

.top_bar {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
}

.top_bar ul {
    padding: 0;
    margin: 0;
}

.top_bar ul li {
    margin-right: 15px;
}

.top_bar ul li a {
    color: #ffffff;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #000;
}

.top_bar ul li a>span {
    margin-left: 10px;
    display: inline-block;
}

.top_contact_detail {
    display: flex;
    align-items: center;
}

.top_social {
    display: flex;
    align-items: center;
}

.navbar.bg-dark {
    background-color: #000 !important;
}

.navbar-brand img {
    max-width: 600px;
}

a.navbar-brand {
    display: inline-block;
    width: 100%;
    background: #000;
    text-align: center;
    padding: 5px 0px;
    margin: 0;
}

nav.navbar.navbar-dark {
    border-bottom: 1px solid rgba(208, 161, 109, 0.3);
    border-top: 1px solid rgba(208, 161, 109, 0.3);
}

ul.navbar-nav .nav-item .nav-link {
    padding: 10px 14px;
}

.footer_sec {
    background: #eee;
    color: #000;
    padding: 0px;
}

.sub_form {
    position: relative;
    margin-top: 30px;
}

.sub_form .form-control {
    height: 45px;
}

.contact_list {
    padding: 24px 14px 10px 15px;
}


.check_wrap {
    font-size: 14px;
}

ul.social_icons li a img {
    max-height: 28px;
}

span.con_icon {
    min-width: 40px;
    min-height: 40px;
    line-height: 20px;
    width: 40px;
    height: 40px;
}

ul.social_icons {
    justify-content: center;
}

.headings_2 {
    margin-bottom: 0;
}

.sub_form .btn-secondary {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    border: none;
    padding: 9px 20px;
    border-radius: 0 8px 8px 0px;
}

.left_sub {
    padding: 100px;
}

.left_sub h2 {
    font-weight: 900;
    font-size: 50px;
    margin-bottom: 20px;
}

.left_sub>p {
    font-size: 20px;
    line-height: 30px;
}

.footer_banner {
    position: relative;
}

.footer_banner img {
    position: relative;
    width: 100%;
}

.footer_banner::before {
    content: "";
    position: absolute;
    background: #D3954F;
    top: 50%;
    width: 100px;
    z-index: 0;
    left: -50px;
    bottom: 0;
}

.copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
}

ul.footer_links {
    display: flex;
}

ul.footer_links li {
    margin-left: 20px;
}

ul.footer_links li a {
    color: #fff;
    font-weight: 600;
}

nav.navbar.navbar-dark {
    padding: 15px 0px;
}

.check_wrap .btn-link {
    color: #ffffff;
    font-size: 14px;
}

.left_form .form-group {
    position: relative;
}

.left_form .form-group .input-group-append {
    position: absolute;
    top: 0;
    right: 0;
}

.left_form .form-group .input-group-append .input-group-text {
    background: transparent;
    border: none;
}

/*-------Profile Style--------*/


/* Style for the dropdown toggle button */
.dropdown-toggle {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

/* Style for the avatar image */
.card.whflex span img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

/* Style for the card container */
.card {
    display: flex;
    align-items: center;
    padding: 3px;
    border-radius: 0.25rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent;
    background-clip: border-box;
    border: transparent;
    border-radius: 0.25rem;
}


/* Style for the card content */
.content {
    margin-left: 8px;
}

/* Style for the dashboard user */
.dashboard_user {
    margin: 0;
}

.dropdown-item img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.top_social .dropdown-toggle {
    align-items: center;
    color: #ffffff;
}

.card.whflex {
    flex-direction: row;
}

.header-dropdown ul.dropdown-menu.side_profile {
    min-width: 340px;
    border: 1px solid #ededed;
    border-radius: 8px;
    overflow: hidden;
}

.header-dropdown ul.dropdown-menu.side_profile .card {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #ededed;
    padding: 15px;
    flex-direction: row;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
}

.header-dropdown ul.dropdown-menu.side_profile .card span {
    min-width: 60px;
    height: 60px;
    max-width: 60px;
    /* background: #f5f5f5; */
    border-radius: 6px;
}

.header-dropdown ul.dropdown-menu.side_profile .card span img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.header-dropdown ul.dropdown-menu.side_profile .card .content {
    width: 100%;
}

.header-dropdown ul.dropdown-menu.side_profile ul {
    padding: 10px 15px;
}

.header-dropdown ul.dropdown-menu.side_profile ul li {
    border: none;
}

.header-dropdown .dropdown-menu li .dropdown-item {
    color: #737791;
    font-size: 14px;
    padding: 10px 20px;
}

.header-dropdown ul.dropdown-menu.side_profile ul li a {
    color: #000;
    padding: 12px;
    cursor: pointer;
}

.header-dropdown ul.dropdown-menu.side_profile ul li a img {
    max-width: 18px;
    margin-right: 12px;
}

.header-dropdown ul.dropdown-menu.side_profile .card .content h5 {
    font-size: 17px;
}

.header-dropdown ul.dropdown-menu.side_profile .card .content p {
    font-size: 13px;
    margin: 0;
}

.header-dropdown ul.dropdown-menu.side_profile ul li a:hover {
    color: #b97a57;
    background: #f5f5f5;
}

.auth-card {
    padding: 40px;
    box-shadow: 0px 2px 10px rgba(255, 2555, 255, .361);
    border-radius: 30px;
    margin: auto;
    max-width: 540px;
    width: 100%;
    background-color: #000;
    border: 1px solid rgba(255, 255, 255, .125);
}

.auth-card form.form-horizontal {
    width: 100%;
}

.auth-card .input-group-text {
    background-color: #222222;
    border-color: #111111;
}

.auth-card button {
    padding: 6px 27px;
}

.btn-primary {
    color: #fff;
    background-color: #FBAF5D;
    border-color: #FBAF5D;
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
    background-color: #905738 !important;
    border-color: #905738 !important;
    outline-color: rgba(64, 66, 215, .23) !important;
    box-shadow: none !important;
}

.me-2 {
    margin-right: 0.5rem !important;
}

.btn {
    padding: 6px 16px;
    font-size: 14px;
    border-radius: 3px;
    line-height: 1.9;
    min-height: 40px;
}

.btn-bordered {
    border: 1px solid #FBAF5D !important;
    color: #FBAF5D !important;
}

.auth-card>h4 {
    display: inline-block;
    width: 100%;
}

.auth-card form .form-group .input-group input {
    padding-left: 15px;
    border-radius: 3px !important;
    height: 40px;
    font-size: 12px;
    color: #fff;
}

.check_wrap .form-check {
    padding-left: 0;
}

.right-section {
    gap: 10px;
}

.user-access {
    height: 42px;
}

.calender-group .container.banner-container {
    max-width: 1800px !important;
}

@media(max-width: 767px) {
    .search-order-outer input {
        font-size: 11px !important;
    }

    .footer-section .row .col-sm-6 {
        flex: 100%;
        justify-content: center !important;
        text-align: center !important;
    }

    .Software_Modules_inner {
        padding-bottom: 0;
    }

    .contact_section {
        padding-top: 0;
    }

    section.Email_Verification .contact_inner h2 {
        font-size: 24px;
        margin: 0 0 30px !important;
    }

    .auth-card {
        padding: 18px;
        border-radius: 10px;
    }

    .auth-card>h4 {
        font-size: 18px;
    }

    .box_inner {
        margin-bottom: 20px;
        padding: 30px;
    }

    .box_inner p {
        min-height: auto;
    }

    .right_contact {
        margin: 20px auto 0;
    }


    .headings_3 h3 {
        font-size: 20px;
    }

    .headings_3>h2 {
        font-size: 30px;
    }

    .box_outer {
        margin: 25px auto 0px;
    }

    .left_sub {
        padding: 20px;
    }

    .left_sub h2 {
        font-size: 28px;
    }

    .top_bar ul li a {
        font-size: 12px;
    }

    ul.footer_links li a {
        font-weight: 400;
    }

    ul.footer_links li {
        margin-left: 0;
    }

    ul.footer_links li+li {
        margin-left: 15px;
    }

    ul.footer_links li a {
        font-size: 14px;
    }

    .footer_banner::before {
        display: none;
    }

    nav.navbar.navbar-dark {
        border: none;
        position: inherit;
        padding: 0 !IMPORTANT;
    }

    .navbar-toggler {
        position: absolute;
        right: 10px;
        top: 57px;
        border: none;
        padding: 0 !important;
    }

    a.navbar-brand {
        width: auto;
        padding-left: 0px;
    }

    .navbar-brand img {
        max-width: 230px;
    }

    ul.navbar-nav .nav-item .nav-link {
        padding: 10px 10px;
        border-bottom: 0.5px solid;
    }

    .navbar-collapse {
        /* flex-basis: 100%; */
        /* flex-grow: 1; */
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        top: 100px;
        max-width: 100%;
        z-index: 99;
        background: #000000;
    }

    .top_bar {
        padding: 5px 0px;
    }

    .top_bar ul {
        flex-wrap: wrap;
        justify-content: center;
    }

    .top_bar ul li {
        margin-right: 10px;
    }

    .copyright {
        justify-content: center;
        flex-wrap: wrap;
        padding: 10px 20px;
    }

    .copy_left {
        margin-bottom: 10px;
    }
}

.mailforgot {
    color: #b97a57;
}

.text-link {
    color: #b97a57;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    background-image: none;
}

.footer--logo img {
    max-width: 110px;
}

.footer--logo {
    display: inline-flex;
    align-items: center;
    gap: 10px;
}

.contact_inner {
    padding-bottom: 30px;
}

.contact_inner .form-control {
    color: #fff;
}

.contact_inner .form-control::-webkit-input-placeholder {
    /* Edge */
    color: #fff;
}

.contact_inner .form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
}

.contact_inner .form-control::placeholder {
    color: #fff;
}

.common {
    min-height: calc(100vh - 263px);
    padding: 0 0 20px;
    /* min-height: calc(100vh - 177px);*/
}

.login-page {
    min-height: calc(100vh - 153px);
}

.forgot-page {
    min-height: calc(100vh - 170px);
}

.Accessibility_section {
    padding: 50px 0px;
    position: relative;
}

.common-table .ant-table {
    background: transparent;
    color: #fff;
}

.common-table .ant-table tr:hover td {
    color: #000 !important;
}

.common-table th.ant-table-cell {
    background: #FBAF5D !important;
    border: none !important;
    white-space: nowrap;
    color: #fff !important;
}

.common-table th.ant-table-cell::before {
    display: none;
}

.coverage-table .ant-table {
    background: transparent;
}

.coverage-table .ant-table tr:hover td {
    color: #000 !important;
}

.coverage-table th.ant-table-cell {
    background: #FBAF5D !important;
    border: none !important;
    white-space: nowrap;
}

.coverage-table th.ant-table-cell::before {
    display: none;
}

.wk-select select {
    height: 40px;
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #b97a57;
    border-color: #b97a57;
}

.wk-select {
    margin-bottom: 20px;
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    /*align-items: center;*/
    gap: 15px
}

.wk-select1 select {
    height: 40px;
    background: #fff;
}

.wk-select1 {
    margin-bottom: 20px;
    width: auto;
    gap: 8px;
    align-items: center;
}

.wk-select1 span {
    white-space: nowrap;
}

.EventCalendar {
    margin-bottom: 20px;
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 20px;
}

.btn-new {
    background: #fbaf5d;
    border-color: #fbaf5d;
}

.btn-newgroup {
    background: #242b31;
    border-color: #242b31;
}

.group-log {
    padding: 2px 10px;
    min-height: inherit;
}

.btn-newgroup:hover {
    background: #242b31 !important;
    border-color: #242b31 !important;
}

.btn-newgroup:active,
.btn-newgroup:focus {
    background: #242b31 !important;
    border-color: #242b31 !important;
}

.content-area {
    padding: 26px 15px;
    border: 1px solid #ffff;
    border-radius: 4px;
    margin: 15px 0;
}



.content-area input,
.content-area textarea,
.content-area select {
    background: #fff !important;
}

.backBTN {
    display: flex;
    gap: 5px;
    font-size: 17px;
    align-items: center;
    color: #fff !important;
    background: transparent;
}

input[type="checkbox"] {
    accent-color: #fbaf5d;
}

.content-area .form-group .phnumber input {
    width: 100%;
    padding: 15px 14px 18.5px 60px;
}

.content-area .form-group .phnumber .country-list input {
    width: 95%;
    padding: 8px 15px;
    margin: 0;
}

.phnumber .special-label {
    display: none;
}

.access {
    width: auto;
}

.company--logo-wrap .fileContainer {
    box-shadow: none;
    margin: 0;
    padding: 0;
    background-color: transparent;
}

.company--logo-wrap .fileContainer .deleteImage {
    background: #bf3e3e;
    font-size: 13px;
    height: 24px;
    line-height: 25px;
    width: 24px;
}

.company--logo-wrap .fileContainer .uploadPictureContainer {
    border-radius: 10px;
    height: 150px;
    margin: 0;
    width: 150px;
}

.company--logo-wrap .fileContainer .chooseFileButton {
    background-color: #edf2f6 !important;
    border: 1px solid #d0dbe4;
    border-radius: 10px !important;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, .1);
    color: #505050 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    height: 150px;
    line-height: 22px;
    width: 150px;
}

.company--logo-wrap .uploadPicturesWrapper {
    position: absolute;
}

.company--logo-wrap {
    flex: 0 0 200px;
    margin-right: 30px;
}

.disabled-input[disabled] {
    background: #f1f3f3 !important;
    color: #888 !important;
}

.disabled-input[disabled]:hover {
    cursor: not-allowed !important;
    background-color: #e6e7e7 !important;
}

.company_logo {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 30px;
    gap: 15px;
}



.colors_swatches {
    display: flex;
    align-items: center;
}

.theme-inner {
    background: #fff;
    padding: 20px 20px;
    border-radius: 10px;
    min-height: calc(100vh - 293px);
}

.company_logo img {
    background: #fff;
    max-width: 200px;
    height: auto;
    padding: 13px 10px;
    max-height: 60px;
}

.theme-inner .ant-select .ant-select-selector {
    background-color: transparent;
    border: none;
    box-shadow: none !important;
}

.theme-inner .ant-select {
    background: transparent;
    border: 1px solid #ccc;
    width: 100% !important;
    padding: 6px 0px;
}

.theme-inner label {
    color: #000;
    font-size: 13px;
    margin: 0;
}

.Ant-select-outer .ant-select .ant-select-selector {
    background-color: transparent;
    border: none;
    box-shadow: none !important;
}

.Ant-select-outer .ant-select {
    background: #fff;
    border: 1px solid #fff;
    width: 100% !important;
    padding: 6px 0px;
}

.Ant-select-outer label {
    color: #fff;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.color-picker {
    gap: 15px;
}

.color-picker>div {
    width: 50% !important;
    border-radius: 4px;
}

.custom-dashboard-height {
    min-height: calc(100vh - 266px);
}

.appsdata {
    min-height: calc(100vh - 288px);
}

.common.Themes {
    min-height: calc(100vh - 284px);
}

.main_section {
    background-color: #000;
}

.uploaded_images-wrap {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    width: 100%;
}

.company--logo-wrap .uploaded_images-wrap {
    left: 0;
    position: absolute;
    top: 10px;
}

.company--logo-wrap .uploaded_images-wrap .img--box {
    align-items: center;
    background: #edf2f6;
    border: 1px solid #d0dbe4;
    border-radius: 10px;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, .1);
    display: flex;
    flex: 0 0 150px;
    height: inherit;
    height: 150px;
    justify-content: center;
    margin: 0;
    padding: 10px;
    position: relative;
    width: 150px;
}

.company--logo-wrap .uploaded_images-wrap .img--box>span {
    font-size: 13px;
    height: 24px;
    line-height: 23px;
    right: -9px;
    top: -9px;
    width: 24px;
}

.company--logo-wrap .uploaded_images-wrap .img--box img {
    background-color: #edf2f6;
    border: 0;
    object-fit: contain;
}

.company--logo {
    position: relative;
}

.uploaded_images-wrap .img--box>span {
    align-items: center;
    background: #bf3e3e;
    border-radius: 50%;
    box-shadow: 1px 3px 10px rgba(0, 0, 0, .5);
    color: #fffcfc;
    cursor: pointer;
    display: flex;
    font-family: monospace;
    font-size: 12px;
    font-weight: 400;
    height: 19px;
    justify-content: center;
    line-height: 25px;
    padding-left: 1px;
    position: absolute;
    right: 3px;
    text-align: center;
    top: -11px;
    width: 19px;
}

.Viewer-table {
    border: 1px solid #fbaf5d;
    border-radius: 10px;
    overflow: hidden;
}

.Viewer-table .ant-table {
    overflow: auto;
}

.Viewer-table table tr th {
    line-height: 17px;
}

.main_section .react-tel-input .form-control {
    width: 100% !important;
    padding: 6px 15px 6px 50px !important;
}

/*.Viewer-table.index--table table tr td:last-child {
    width: 120px;
    min-width: 120px;
}*/

@media (max-width: 767px) {
    .company--logo-wrap {
        margin-right: 0;
    }

    .company-photo-container {
        flex-direction: column;
    }

    .soft-module-outer {
        max-width: 100% !important;
        flex: 100% !important;
    }
}

.select-Role .ant-select {
    width: 100%;
}

.select-Role .ant-select .ant-select-selector {
    height: auto;
    min-height: 42px;
    border-radius: 8px;
    border: 1px solid #D1D5DB;
    display: flex;
    align-items: center;
}



.select-Company .ant-select-selector {
    min-height: 42px;
}

.dis_image {
    display: none;
}

.grouping-div {
    border: 1px solid #fff;
    padding: 40px;
    padding-top: 20px;
}

.main_logo img {
    /*height: 50px;*/
    width: auto;
    max-height: 100px;
}

.address span {
    width: 100%;
    display: inline-block;
    WHITE-SPACE: nowrap;
    OVERFLOW: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.description span {
    width: 100%;
    display: inline-block;
    WHITE-SPACE: nowrap;
    OVERFLOW: hidden;
    text-overflow: ellipsis;
    max-width: 145px;
}

.description_projects span {
    width: 100%;
    display: inline-block;
    WHITE-SPACE: nowrap;
    OVERFLOW: hidden;
    text-overflow: ellipsis;
    max-width: 255px;
}

.description_support span {
    width: 100%;
    display: inline-block;
    WHITE-SPACE: nowrap;
    OVERFLOW: hidden;
    text-overflow: ellipsis;
    max-width: 155px;
}



ul.ant-pagination {
    padding: 0 29px;
}

.react-tel-input .country-list .country-name {
    color: #000;
}

.count {
    position: absolute;
    right: 0;
    bottom: -20px
}

.wk-form-grup .invalid-feedback {
    position: absolute;
    bottom: -22px;
}

.wk-form-grup .form-group {
    margin-bottom: 45px;
}

.form-control.input-bg {
    background: #fff;
    color: #000;
}

.form-control.input-bg::placeholder {
    color: #7a7575;
}

.chrome-picker {
    position: absolute;
    top: 28px;
    right: 0;
    z-index: 9;
}

.color-theme {
    position: relative;
}

.pl::placeholder {
    color: #000
}

/*.drop-btn.nav-item .dropdown .dropdown-toggle {
    padding-top: 10px;
    color: rgba(255, 255, 255, 0.5);
}*/
.navbar-nav .nav-item:hover .drop-box {
    display: block;
}

.navbar-nav .nav-item {
    position: relative;
}

.drop-box a {
    color: #616161 !important;
    font-size: 14px;
    padding-bottom: 10px;
    cursor: pointer;
    display: block;
}

.drop-box a:hover {
    color: #000 !important;
    font-weight: 500px;
}

.drop-box {
    display: none;
    background: #fff;
    z-index: 999;
    border-radius: 3px;
    left: 30px;
    position: absolute;
    top: 40px;
    padding: 10px 15px;
    width: 200px;
    max-width: 200px;
}

.subcompany {
    min-width: 200px;
}

.item-filter .searchButton {
    width: 40px;
    position: absolute;
    right: 0;
    border-radius: 0px 4px 4px 0;
    background: transparent;
    bottom: 0;
    top: 0;
    z-index: 1;
    cursor: pointer;
}

.item-filter input.form-control {
    padding-right: 40px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.item-filter .filter-icon {
    z-index: 9;
    top: 0;
    left: 10px;
    bottom: 0;
    margin: auto;
    height: 25px
}

.searchButton {
    width: 40px;
    height: 100%;
    position: absolute;
    right: 0;
    border-radius: 0px 4px 4px 0;
    background: transparent;
}

.searchBox .form-control:focus {
    outline: none !important;
    border-color: transparent !important;
    box-shadow: none;
    border: none;
}

.modal-body .form-control {
    border: 1px solid var(--smart-border);
}

.searchBox input.form-control {
    padding-right: 40px;
    color: white !important;
    width: 310px;
    background-color: rgba(211, 149, 79, 0.15) !important;
    border: 1px solid rgba(211, 149, 79, 0.2);
}

.item-filter input.form-control:focus {
    border: 1px solid #fff;
    background: transparent !important;
    color: #fff;
}

.item-filter input.form-control {
    color: #fff !important;
}

.searchBox input::-ms-input-placeholder {
    color: white;
}

.searchBox input::placeholder {
    color: white;
}

.error-wrap {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-top: 15%;
}

.error-wrap h1 {
    font-size: 42px;
    font-weight: bold;
}

.error-wrap h5 {
    font-size: 22px;
    font-weight: bold;
}

.form-group.filter-wrap .form-select,
.comdropdown.filter-wrap .form-select,
.company-drop {
    max-width: 800px;
}



.logout-admin {
    color: orangered;
}

.error-img img {
    width: 80px;
    height: 80px
}

.create-work-item .dropdown .dropdown-menu li a,
.recent-updates .dropdown .dropdown-menu li a {
    padding: 5px 10px
}

.create-work-item .dropdown .dropdown-menu li a p,
.recent-updates .dropdown .dropdown-menu li a {
    font-size: 14px;
}

.filteration-wrap {
    background: #000;
    border-radius: 8px;
}

.item-filter input {
    padding-left: 50px;
    background: transparent;
    border: 1px solid #fff;
    z-index: 1;
}

.item-filter input:focus {
    color: #fff;
}

.filter-icon {
    z-index: 999;
    top: 25px;
    left: 30px;
}

.common-drop.dropdown .dropdown-toggle::after {
    margin-left: 10px
}

.more-filter {
    text-align: right;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.dropdown button:focus {
    box-shadow: none;
}

.dropdown.common-drop .dropdown-menu {
    width: 250px;
    max-width: 250px;
}

.dropdown.group-drop .dropdown-menu {
    width: 100%;
    max-width: 100%;
}

.more-filter ul li .dropdown button {
    padding-left: 20px;
    padding-right: 20px;
}


.item-filter input::placeholder {
    color: #ffffff80
}

.row-check {
    margin: -6px;
    width: 15px;
    height: 15px;
}

.assigned-name {
    width: 25px;
    height: 25px;
    background: #fff;
    border-radius: 20px;
    padding: 3px;
    font-size: 12px;
    color: #000
}



.assigned-user.dropdown .dropdown-toggle {
    width: 300px;
    text-align: left;
    border: 1px solid #fff;
}

.assigned-user.dropdown .dropdown-toggle {
    padding-left: 50px;
}

.assigned-user.dropdown .dropdown-toggle::after {
    right: 15px;
    position: absolute;
    top: 20px;
}

.assigned-user.dropdown .dropdown-toggle p {
    top: 6px;
    left: 10px;
}

.assigned-user.dropdown .dropdown-menu {
    width: 300px
}

.name-prefix {
    min-width: 30px;
    width: 30px;
    height: 30px;
    background: rgb(0, 68, 131);
    border-radius: 20px;
    padding: 4px;
    font-size: 12px;
    color: #fff !important;
    text-align: center;
}

.name-prefix-unassigned {
    width: 30px;
    height: 30px;
    background: rgb(164, 199, 231);
    border-radius: 20px;
    padding: 4px;
    font-size: 12px;
    color: #fff !important;
    text-align: center;
}

.name-prefix-profile_picture {
    width: 30px;
    height: 30px;
    background: rgb(164, 199, 231);
    border-radius: 20px;
    font-size: 12px;
    color: #fff !important;
    text-align: center;
}

.name-details p,
.name-details h6 {
    font-size: 14px;
}

.name-details h6 {
    font-weight: 700;
}

.comment-wrap a .badge {
    background: #000;
    padding: 6px 12px;
}

.more-element-wrap,
.discussion-section {
    background: #683a02
}

/*.states-drop {
    width: 200px;
}*/

.states-drop select,
.reason-inputs select {
    background: none;
    padding: 6px;
    border-radius: 4px;
    font-size: 12px;
    width: 150px;
}

.states-drop label,
.reason-inputs label {
    font-size: 14px;
    margin: 0;
}

.reason-inputs .form-control {
    background: white;
}

.last-update small {
    font-size: 12px;
}

.discussion-comment h6 span {
    font-size: 12px;
    opacity: 0.7;
}

.discussion-comment p {
    font-size: 14px;
    opacity: 0.8;
}

.name-prefix.discussion-name {
    width: 60px;
    height: 40px;
    background: #000;
    border-radius: 40px;
    padding-top: 10px
}

.assigned-section a:hover {
    color: black !important;
    text-decoration: none;
}

.QAIcon {
    width: 11% !important;
    height: 10% !important;
    margin-left: auto;
}

.DevIcon {
    width: 11% !important;
    height: 10% !important;
    margin-left: auto;
}

.dateTime {
    min-height: 30px !important;
    padding: 2px 9px !important;
    width: 100% !important;
    border-radius: 6px !important;
}

/*.ant-table-column-sort {
    background: transparent !important;
    color:#fff;
}
    .ant-table-column-sort:hover {
        color: #000;
        background: #fafafa!important;
    }*/
.ant-table-wrapper td.ant-table-column-sort {
    background: none
}

.item-filter input {
    width: 300px;
    max-width: 350px;
    color: #fff;
}

.filter-reset-btn {
    height: fit-content
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.report-title {
    width: 420px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.highlight-comment {
    background-color: #FFFFCC;
    border: 1px solid #FFD700;
    padding: 6px;
    color: black;
}

.non-clickable {
    pointer-events: none;
}

.feedback-btn img {
    width: 24px;
    cursor: pointer;
}

.sitemap-modal-dialog-video {
    width: 100%;
    max-width: 750px;
    margin: auto;
    padding: 20px;
}

.status {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #0f9c0f;
    position: absolute;
    left: 0;
    top: 26px;
}

.ck-editor ul,
.ck-editor ol,
.ck-editor a {
    margin: 1em 0;
    padding-inline-start: 18px;
    color: gray;
    /*list-style-type: none;*/
}

.remove_dots {
    list-style-type: none;
}

.openWorkItem {
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: blue;
    cursor: pointer;
}


.password-btn {
    padding: 10px;
    background: #693a02;
    font-size: 14px;
    border-radius: 8px;
    text-decoration: underline;
    text-decoration-color: blue;
    color: #fff;
}

.password-btn:hover {
    color: #fff;
    text-decoration: underline;
    text-decoration-color: blue;
}

.ant-table-cell div a,
.ant-table-cell a {
    color: inherit;
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-row:hover>td a {
    color: blue;
}

.site--loader {
    background: #00000094;
    overflow-y: hidden;
    position: fixed;
}

.ellips-dec.projectcol {
    width: 140px
}

.no-data {
    background: #ffffff1c;
    border-radius: 20px;
    height: 300px;
    margin: 0 auto;
    padding: 120px 0;
}


.filter-btn {
    background-color: #d4d1cf;
    padding: 5px;
    border-radius: 5px;
    margin-left: 20px;
}

.entries-dropdown-search {
    padding: 20px;
    min-width: 500px;
}

.entries-dropdown-search .btn {
    margin-left: 10px;
}

.action-dropdown {
    background: #fbaf5d;
    padding: 5px 8px;
    border-radius: 4px
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-row:hover>td .ant-dropdown-link {
    background: #fbaf5d;
    color: #fff !important
}

.ant-table-cell.ant-table-cell-row-hover {
    background: #fff !important;
    color: #000 !important;
}

.setting-dropdown {
    width: 150px;
    max-width: 150px;
}

.no-access {
    background: red;
    color: white;
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
}

/*.ant-dropdown-menu{right:70px!important}
*/
.ant-dropdown-link .anticon-down {
    margin-top: 5px
}

.refresh-btn:hover {
    background-color: #FBAF5D !important;
    color: #fff;
}

.names-col {
    width: 280px;
}

.company-col {
    width: 280px;
}

.site-col {
    width: 360px
}

.ant-table-cell.ip-col span {
    width: 200px;
    display: block;
}

.name-col {
    width: 140px
}

.name-col-created {
    width: auto !important
}

.Viewer-table .ant-table-wrapper .ant-table-tbody>tr>td,
.Viewer-table .ant-table-wrapper .ant-table-thead>tr>th {
    padding: 15px 5px !important;
}

.bookmark span img,
.bookmark span,
.delete img,
.reset span img {
    width: 36px;
    height: 36px;
    cursor: pointer;
}

.bookmark span img {
    margin-top: 8px
}

.primary-clr {
    color: #FBAF5D
}

.primary-bg {
    background: #FBAF5D
}

.active-check {
    width: 20px;
    height: 20px;
    background: green;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 1px;
}

.refresh-button img {
    width: 24px;
    height: 24px
}

.logs-address {
    width: 340px
}

/*.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}*/

/*.modal-dialog-video {
    width: 100%;
    max-width: 600px;
}*/

/*.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}*/
.modal-dialog {
    color: #000 !important
}

.custom-form-control.form-control {
    background-color: transparent !important;
    border-radius: 0.25rem !important
}

.logs-menu:hover .logs-submenu {
    display: block
}

.logs-submenu {
    display: none;
    font-size: 12px;
    top: 4px;
    border-radius: 0px 4px 4px 0;
    position: absolute;
    left: 100%;
    background: #fff;
}

.setting-dropdown {
    padding: 0;
}

.setting-dropdown a {
    padding: 6px 10px;
}

.drop-btn span img,
.logs-drop span img {
    width: 12px
}

.logs-drop span img {
    rotate: 270deg;
}

.drop-box a {
    border-bottom: 1px solid #e2e2e2
}

.alarm-toggle--wrap .ant-switch.ant-switch-checked {
    background-color: #FBAF5D;
}

.alarm-toggle--wrap .ant-switch.ant-switch-checked:hover {
    background-color: #FBAF5D !important;
}

.review-img {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.msg-icon {
    height: 20px;
}

.ant-select-dropdown .ant-select-item-option .ant-select-item-option-state .anticon {
    flex: none;
    display: flex;
    align-items: center;
    width: 30px;
    text-align: center;
    justify-content: center;
    height: 30px;
    border-radius: 50px;
    border: 1px solid #1677ff;
}

/*@media screen and (max-width: 1366px) {
    .sitebuttons ul {
        width: 100px
    }
}
@media screen and (min-width: 1280px){
    .sitebuttons ul {
        width: 100px
    }
}*/
.date-wrap {
    width: 200px
}

.name-wrap {
    width: 200px
}


/*.left-section1,

.right-section1 {
    flex: 1;
    border: 1px solid #f0f0f0;  
}*/
.support td {
    white-space: nowrap;
}




.Software_Modules_inner .box_inner {
    border-bottom: 0;
    padding: 25px;
}

.Software_Modules_inner .box_inner button.logo_str {
    border-radius: 50px;
    padding: 5px 12px;
    font-size: 14px;
    background: #fff;
    color: #000 !important;
    margin: 0;
}

.Software_Modules_inner .box_inner .redirect-icon {
    width: 20px;
    cursor: pointer;
}

.Software_Modules_inner .box_inner p.description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    font-weight: 500;
    margin: 20px 0 25px;
}

.Software_Modules_inner .box_inner .card-bottom li.sitename {
    font-size: 14px;
    padding: inherit;
    color: #fff;
    padding-left: 15px;
    position: relative;
}

.Software_Modules_inner .box_inner .card-bottom ul {
    align-items: center;
    gap: 10px;
}

.Software_Modules_inner .box_inner .card-bottom ul li img {
    width: 20px;
    position: relative;
    top: -1px;
    gap: 5px;
}

.Software_Modules_inner .box_inner .card-bottom ul li {
    align-items: center;
    line-height: 24px;
}

.Software_Modules_inner .box_inner button.logo_str:hover {
    background: #fff;
    color: #000;
}

.Software_Modules_inner .box_inner .card-bottom li.sitename::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50px;
    background: #fff;
    left: 0;
    position: absolute;
}

.Software_Modules_inner .box_inner .card-bottom ul li.notification img {
    filter: none;
    position: relative;
    top: 0;
}

.edit-img {
    filter: brightness(0) invert(1)
}

.acknowledge-btn img {
    width: 20px
}

.blank-row-tb .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-tbody tr.ant-table-measure-row {
    display: none
}

.blank-row-tb .ant-table-wrapper .ant-table-thead>tr>th,
.blank-row-tb .ant-table-wrapper .ant-table-tbody>tr>td {
    padding: 3px 5px !important;
}

.blank-row-tb ul.ant-pagination {
    margin: 0 !important;
    padding: 6px;
}

.notification--btn {
    position: relative;
    margin-right: 15px;
}

.notification--btn::after {
    display: none;
}

.notification--btn span {
    background: red;
    min-width: 18px;
    height: 18px;
    padding: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 100%;
    font-size: 10px;
    right: 10px;
    top: 2px;
    color: #fff;
}

.notification--btn img {
    height: 24px;
}

.soft-module-inner {
    background: #EEEFF2;
    border-radius: 5px;
    color: #fff;
    height: 100%;
    z-index: 1;
    position: relative;
}

.soft-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px;
    border-radius: 5px 5px 0 0;
    align-items: center;
    background: linear-gradient(355.59deg, rgba(0, 0, 0, 0) 3.48%, rgba(0, 0, 0, 0.45) 96.34%);
}

.soft-content {
    text-align: center;
    display: inline-flex;
    justify-content: center;
    width: 100%;
    gap: 10px;
    padding: 30px 0 30px;
}

.soft-btn {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.cat-btn {
    border-radius: 30px;
    padding: 2px 11px;
    background-color: #B97A57;
    border-color: #B97A57;
    min-height: 36px;
    margin-right: 20px;
}

.supportgrp-btn {
    border-radius: 30px;
    padding: 1px 9px;
    background-color: #B97A57;
    border-color: #B97A57;
    min-height: 38px;
    margin-right: 20px;
}

.soft-footer {
    background: #000;
    padding: 13px 10px;
    text-align: center;
}

.soft-footer img {
    max-height: 28px;
    max-width: 253px;
}

.sub-logo img {
    max-height: 20px;
    max-width: 206px;
}

.soft-alaram-count {
    display: inline-flex;
    border-radius: 3px;
}

.soft-alaram-count h3 {
    width: 100%;
    margin: 0;
    font-size: 40px;
    font-weight: 700;
    color: #8E5637;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
}

.soft-alaram-count span {
    color: #000000;
}

.sub-company-name {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #232323;
    font-size: 16px;
    font-weight: 600;
}

.soft-alarm {
    width: 80px;
    height: 80px;
    background: #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

.soft-alarm img {
    max-height: 50px;
}

.sub-company-name {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #232323;
    font-size: 16px;
    font-weight: 600;
    /* border-right: 1.5px solid #00000040;
        padding-right: 10px; */
    margin-right: 10px;
}

.soft-border-right {
    display: flex;
    align-items: center;
    gap: 10px;
    border-right: 1px solid #CCCCCC;
    flex: 1;
    padding-right: 20px;
    margin-right: 20px;
    padding-left: 20px;
}

.company-logo {
    height: auto;
    text-align: center;
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bg-color {
    padding: 4px;
}

.company-logo.bg-color img {
    max-height: 45px;
}

.logo-maxheight {
    max-height: 40px;
    max-width: 140px;
}

.alarm-badge {
    width: 30px;
    height: 30px;
    background: #fff;
    color: #000;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.apps-col {
    color: #000;
    border-radius: 2;
    font-size: 12px;
}

.support-badge {
    background: red;
    min-width: 18px;
    height: 18px;
    padding: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 100%;
    font-size: 10px;
    color: #fff;
}

.customAlarms {
    background-color: #fff;
    color: #000;
    width: auto;
    height: auto;
    padding: 4px 15px;
}

.customAlarms img {
    border-right: 1px solid #a6a6a6;
    margin-right: 10px;
    padding-right: 10px;
    width: 27px;
}

/*.copy-model {
    text-align: center;
    padding: 20px 0;
}*/

.btn-yesno .btn {
    min-height: inherit;
    padding: 2px 5px !important;
}

.req-img {
    width: 100px
}

.notify {
    position: absolute;
    top: -5px;
    right: -10px;
    margin: 0;
}

.img-height {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
}

.img-height img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.dropdown.header-dropdown button:after {
    display: none;
}

.profile-pic-size {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
}

.padding-45 {
    padding-bottom: 35px !important;
}

.mar-right-15 {
    margin-right: 15px;
}





.profile-box .card-box {
    border-radius: 4px;
    background: white;
    color: black;
}

.profile-box .card-box .head {
    padding: 20px 15px;
}

.update-profile span {
    width: 150px;
    height: 150px;
    overflow: hidden;
    display: block;
    margin: 0 auto 20px;
    border-radius: 50px;
}

.update-profile span img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.powered-by {
    font-size: 9px;
}

.flex-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.blink {
    animation: blinker 1s linear infinite;
    font-family: sans-serif;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.workitemHigh {
    padding: 2px;
    margin: 0px 0px 5px 0px;
}

/* Css3 tags */

.resolvedWorkItem {
    display: inline-block;
    /* height: 20px; */
    /* line-height: 23px; */
    position: relative;
    /* margin: 0 12px 8px 0; */
    padding: 0px 5px 0 5px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    /* box-shadow: 0 1px 2px rgba(0,0,0,0.2); */
    color: #fff;
    font-size: 11px;
    /* font-family: "Lucida Grande", "Lucida Sans Unicode", Verdana, sans-serif; */
    /* text-decoration: none; */
    /* text-shadow: 0 1px 2px rgba(0,0,0,0.2); */
    font-weight: bold;
    background: #f58220;
    border-color: transparent transparent transparent #f58220
}

.closedWorkItem {
    display: inline-block;
    position: relative;
    padding: 0px 5px 0 5px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    background: #97c224;
    border-color: transparent transparent transparent #97c224
}

.activeWorkItem {
    display: inline-block;
    position: relative;
    padding: 0px 5px 0 5px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    background: #00A6B8;
}

.activeSupportItem {
    display: inline-block;
    position: relative;
    padding: 0px 5px 0 5px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    background: #00A6B8;
}

.noAssignedName {
    display: inline-block;
    position: relative;
    padding: 0px 5px 0 5px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    background: #00A6B8;
}

.support-btn {
    padding: 0px 5px 0 5px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #FBAF5D;
    color: #ffffff;
}

.company-drop.callorder .ant-select-selector {
    padding: 5px 11px !important;
}

.redirect-ico {
    border-left: 1.5px solid #00000040;
    padding-left: 10px;
}

.form-control.callorder {
    background: #fff !important;
}

@media (max-width:800px) {
    .button-mb {
        margin-bottom: 10px;
    }
}

.preview-btn img {
    width: 18px;
    filter: invert(1)
}

.preview-btn.btn {
    padding: 6px 8px !important;
}

.preview-box {
    padding: 15px;
    border: 1px solid #ffff;
    border-radius: 4px;
    margin: 15px 0;
    position: sticky;
    top: 0
}

.preview-head {
    height: 30px;
    background: #b97a56
}

.preview-body {
    height: 170px;
    background: #464646
}

.preview-footer {
    height: 40px;
    font-size: 8px;
    background: #b97a56;
}

.preview-footer p img {
    width: 60px;
    height: 30px;
    object-fit: contain
}

.preview-menubar img {
    height: 30px;
}

.preview-menubar {
    background-color: #000;
}

.preview-empty {
    background: #fff;
    color: #000;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center
}

.preview-theme {
    text-align: center;
}

.support-audio {
    height: 27px;
    padding-top: 5px;
    width: 251px;
}

.bg-color-orange .ant-collapse-header {
    background-color: rgb(251 175 93);
    border-radius: 8px !important;
    color: #fff !important;
}

.bg-color-orange .ant-collapse-header[aria-expanded="true"] {
    border-radius: 8px 8px 0 0 !important;
}

.bg-color-orange .ant-collapse-content {
    background: transparent;
}

.bg-color-orange {
    border-color: rgb(251 175 93);
}

.bg-color-orange .ant-collapse-item,
.bg-color-orange .ant-collapse-content {
    border-color: rgb(251 175 93) !important;
}

.uploadPictureContainer img.uploadPicture {
    width: 100%;
    height: 100%;
    object-fit: contain;
}



#supportBox {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.supportBoxAck,
.supportBoxAssign,
.supportBoxClosed,
.supportBoxWorking {
    float: left;
    width: 25%;
    padding: 5px;
}

.box {
    border-radius: 7px;
    height: auto;
    padding: 5px 14px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    background: #eeeeee47;
    width: auto;
    text-align: center;
    min-width: 140px;
    justify-content: center;
}

.sitebuttons ul {
    display: flex
}

.alarm-toggle--wrap {
    margin-right: 10px
}

.app-btnswrap {
    display: inline-flex;
    gap: 10px;
    flex-direction: column;
    row-gap: 22px;
}

.supportrequest-col {
    min-width: 550px
}

.box h4 {
    background: #fbaf5d;
    border-radius: 6px;
    width: 30px;
    height: 30px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin: 0;
}

.box p {
    margin: 0;
}

.app-col {
    width: 100px
}

/*.supportrequest-col.ant-table-cell.ant-table-cell-row-hover div #supportBox .logs-icon {
    background: black;
}*/
/*.dashboard-tb.Viewer-table .ant-table-content table tr:hover{background-color:transparent!important;background:transparent!important}*/


.ant-table-content table tr:hover td div .logs-icon button {
    background: transparent !important
}

.logs-icon button {
    background: #fbaf5d;
    padding: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.logs-icon button img {
    filter: grayscale(0) invert(1);
    max-height: 20px;
    margin-right: 10px;
}

.support-logs {
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 30px
}

.support-logos img {
    height: 90px;
    object-fit: contain;
}

.support-logos label,
.support-logos img {
    margin-bottom: 10px
}

.support-logos {
    width: 100%;
    max-width: 180px;
    height: auto;
    margin: 0 auto
}

.support-logos label {
    color: #000;
    font-size: 20px;
    font-weight: 600
}

.support-logos h5 {
    background: #454545;
    font-size: 18px;
}

.dashboard-tb .ant-table-wrapper .ant-table-tbody>tr.ant-table-row:hover>td {
    background: transparent !important;
    color: #fff !important;
}

.boxes-outers {
    margin-bottom: 30px;
}

.box--inner {
    background: #fff;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    border: 1px solid #e3e3e3;
    display: flex;
    align-items: center;
    padding: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    transition: all .3s ease-in-out 0s;
    min-height: 100px;
    text-decoration: none;
}

.box--inner:hover {
    box-shadow: 0 11px 20px rgba(0, 0, 0, .15);
    text-decoration: none;
}

.box--inner:hover:after {
    top: -35px;
}

.dash-icon {
    /*width: 120px;*/
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    border-radius: 0 60px 0 0
}


.box--inner:hover .dash-icon {
    border-radius: 0;
    transition: all .3s ease-in-out 0s;
}

.dashboard-icon1 {
    background: #ffeddf;
}

.dashboard-icon2 {
    background: #e3f7e0;
}

.dashboard-icon3 {
    background: #eaf5ff;
}

.dashboard-icon4 {
    background: #fdeeff;
}

.dash-icon img {
    width: 32px;
    height: 32px
}

.content--box {
    padding: 10px 20px;
    flex: 1
}

.content--box p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
    color: #737791;
    transition: all .3s ease-in-out 0s;
}

.counts-text {
    display: inline-block;
    width: 100%;
    font-weight: 600;
    font-size: 32px;
    line-height: 28px;
    color: #3a3a3a;
}

.analytic-card {
    border: 1px solid #fff;
    border-radius: 8px;
}

.analytic-card h4 {
    padding: 8px 10px;
    font-size: 18px;
    border-radius: 8px 8px 0 0;
    background: #ba7b53
}

.analytic-card-body {
    font-size: 14px
}

.acknowledge-radio-grapgh .radio-chart {
    min-height: 400px
}

.acknowledge-radio-grapgh .radio-chart .apexcharts-canvas {
    height: 400px
}

.analytic-card-body .apexcharts-canvas .apexcharts-toolbar .apexcharts-menu-open .apexcharts-menu-item {
    color: #000;
    text-align: center
}

.view-report {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    padding: 0 16px;
}

.view-report label {
    margin: 0;
}

.load-report {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    padding: 0 16px;
}

.load-log {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: right;
    padding: 0 16px;
}

.load-report label {
    margin: 0;
}

.ratio-filter-listing li a p {
    width: 100%;
    max-width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.roles-dropbox,
.companies-dropbox {
    margin-bottom: 0;
    padding-bottom: 0
}

.companies-dropbox {
    overflow-x: hidden
}

.companies-dropbox-btn,
.roles-dropbox-btn,
.custom-dropbpx {
    border: 1px solid #fff;
    border-radius: 4px;
}

.group-dropbox-btn {
    width: 100%;
}

.group-dropbox-btn>button {
    background: #fff;
    color: #6c7592 !important;
    border-radius: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 30px;
}

.carousel-control-next,
.carousel-control-prev {
    filter: invert(100%);
    width: 3%;
}

.large-modal .ant-modal {
    width: 200%;
    /*max-width: none; */
}

.large-modal .ant-modal-body {
    max-height: 200vh;
    overflow-y: auto;
}

.modal-title {
    padding-left: 32px;
}

.clone-button {
    padding-left: 20px;
}

.autocomplete-places .autocomplete-dropdown-container * {
    padding: 5px 10px 5px 30px;
    border-bottom: 1px solid #eee;
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: 10px center;
    color: #000;
}

.autocomplete-dropdown-container {
    box-shadow: 2px 6px 20px rgb(0 0 0 / 10%);
}

.disabled-input[disabled] {
    background: #f1f3f3 !important;
    color: #888 !important;
}

.disabled-input[disabled]:hover {
    cursor: not-allowed !important;
    background-color: #e6e7e7 !important;
}

.map-icon {
    fill: #fbaf5d !important;
    cursor: pointer;
}

.showMap {
    width: 100px;
}

.map-detail-hove_card {
    width: 230px;
}

.map-detail-hove_card-img {
    flex: 0 0 100px;
    max-height: 78px;
    overflow: hidden;
    text-align: center;
    object-fit: cover;
    height: 100px;
    z-index: 1;
    position: relative;
}

.map-detail-hove_card-img img {
    max-width: 100%;
    max-height: 78px;
    position: absolute;
    top: 0;
    left: 0;
}

.map-detail-hove_card-content {
    flex-grow: 1;
    min-width: 134px;
    display: flex;
    align-items: center;
}

.map-detail-hove_card h5 {
    font-size: 16px;
    margin-bottom: 4px;
}

.map-detail-hove_card b {
    font-size: 14px;
    color: #777;
}

.map-detail-hove_card span,
.map-detail-hove_card b {
    font-size: 14px;
    color: #000;
    font-weight: 500;
}

.gm-ui-hover-effect {
    position: absolute !important;
    right: -13px !important;
    top: -14px !important;
}

.map-detail-hove_card .map-detail_inner {
    flex-grow: 1;
}

.map-detail-hove_card .map-items_direction {
    flex: 0 0 50px;
    cursor: pointer;
}

.map-detail-hove_card .map-items_direction span {
    font-size: 12px;
    font-weight: 500;
    color: #3c79e6;
}

.site-name h4 {
    font-size: 18px;
    color: #000;
    margin: 0;
    font-weight: 700;
}

.site-name {
    background: #eee;
    padding: 13px 10px;
    margin-bottom: 0;
    text-align: center;
}

.site-name h4 span {
    font-size: 20px;
    color: rgb(186, 123, 83)
}

.nested-table {
    display: inline;
}

.nested-table .ant-table table {
    table-layout: auto;
}

.nested-table .ant-table {
    min-width: 1000px;
}

.data-table table th,
.data-table table td {
    padding: 8px 10px !important;
}

.data-table table th:last-child,
.data-table table td:last-child {
    text-align: left !important;
}


.custom-table>.ant-table-wrapper .ant-table table {
    border-collapse: separate;
    border-spacing: 0px 10px;
}

.custom-table>.ant-table-wrapper .ant-table table .ant-table-thead tr td {
    padding: 0;
}

.custom-table>.ant-table-wrapper .ant-table table tr td {
    border-spacing: 0px;
    padding: 10px;
    vertical-align: middle;
    border-bottom: none !important;
}

.custom-table>.ant-table-wrapper .ant-table table tr td:nth-child(2) {
    padding-left: 0;
}

.custom-table .ant-table-wrapper table tbody tr td .custom-child-table table {
    border-collapse: collapse;
    border-spacing: 0px !important;
}

.custom-table .ant-table table tbody>tr.ant-table-row-level-0>td {
    background: #FBAF5D !important;
}

.custom-table .ant-table table tbody>tr.ant-table-expanded-row-level-1 {
    background: #4d4d4d;
}

.custom-table .ant-table table tbody>tr.ant-table-row-level-0 td:first-child {
    border-radius: 8px 0 0 8px;
}

.custom-table .ant-table table tbody>tr.ant-table-row-level-0 td:last-child {
    border-radius: 0px 8px 8px 0;
}

.custom-table .ant-table table tbody>tr.ant-table-expanded-row {
    background: transparent !important;
}

.custom-table .ant-table-wrapper .ant-table {
    background: transparent !important;
}

.custom-table .ant-table-wrapper .ant-table-tbody>tr.ant-table-row:hover>td {
    background: #FBAF5D !important;
}

.custom-table .ant-table-wrapper .ant-table-thead {
    background: #FBAF5D !important;
}

.custom-table .ant-table-wrapper table tbody tr td .custom-child-table tbody tr td {
    background: transparent !important;
}

.custom-child-table table tbody tr td,
.custom-child-table table tbody tr {
    background: transparent !important;
}

.custom-child-table .ant-table table tbody>tr {
    background: transparent !important;
}

.custom-child-table .ant-table table tbody>tr:hover {
    background: #ffffff !important;
}

.custom-table .ant-table-wrapper .ant-table-tbody tr td .custom-child-table tbody tr:hover td {
    background: #fff !important;
}

.custom-table .ant-table-wrapper table>.ant-table-thead>tr td {
    border-color: transparent !important;
    border-width: 0px !important;
}

.custom-table .ant-table-wrapper table tbody tr td .custom-child-table table .ant-table-thead>tr {}

.group-name {
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    font-weight: 600;
}

.group-edit-icon {
    margin-left: 1px;
    cursor: pointer;
    position: relative;
    width: 18px;
    filter: brightness(0.5);
}

.edit-input {
    margin-left: 8px;
    height: 20px;
    font-size: 14px;
    position: absolute;
    left: calc(100% + 8px);
}

.edit-new-groupname {
    padding: 0px 10px 5px !important;
    background-color: white !important;
    border-radius: 3px;
    min-height: 35px;
}

.edit-groupname {
    padding: 8px 16px 5px !important;
    background-color: white !important;
    border-radius: 3px;
    min-height: 40px;
}

.clear-btn {
    position: sticky;
    bottom: 0;
    background: #fff;
    border-top: 1px solid #eee;
    color: #fff;
}

.group-drop .dropdown-toggle::after {
    position: absolute;
    right: 10px;
    top: 17px;
}

.new-group-form {
    padding: 20px;
    background-color: #242b31;
    border: 1px solid #242b31;
    border-radius: 5px;
    position: absolute;
    right: 0px;
    top: 5px;
    width: 300px;
    z-index: 10;
}

.add-group {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
}

.parent-container {
    display: flex;
    position: relative
}

.form-label {
    margin-bottom: 10px;
    color: #ffffff;
    font-size: 16px;
}

.google-map {
    height: 30px;
}

a.backBTN.back-btn {
    position: absolute;
    left: 10px;
    top: 11px;
}

.gap-2 {
    gap: 10px;
}

.site-description {
    border-radius: 8px !important;
}


.add-edit-group-member {
    display: flex;
    gap: 5px;
    align-items: center;
}

.add-group-member {
    margin-right: 5px;
    cursor: pointer;
    position: relative;
    width: 18px;
    filter: brightness(0.5);
}

.ant-popover-inner:has(.new-group-form) {
    padding: 0 !important;
}

.ant-popover:has(.new-group-form) .ant-popover-arrow {
    display: none !important;
}

.Group-name {
    color: #fff;
}

.map-companyLogo img {
    /*max-width: 100% !important;*/
    max-height: 70px;
}

.map-resellercompany-name {
    font-size: 14px;
    color: #000;
}

.map-site-name {
    font-size: 14px;
    color: #000;
}

.nav-item:hover .drop-box.setting-dropdown,
.nav-item .drop-box.setting-dropdown.open {
    display: block;
}


.settings-container {
    border: 1px solid white;
    padding: 30px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.container-title {
    font-size: 16px;
    margin-bottom: 15px;
    position: absolute;
    top: -15px;
    background-color: rgb(88, 88, 88);
    padding: 0 10px;
    left: 10px;
}

.log_table {
    padding: 60px 0px;
}

.assgin-table tr td:first-child,
.assgin-table tr th:first-child {
    text-align: center;
}

.support-row-tb .ant-table-wrapper .ant-table-tbody>tr>td {
    padding: 5px;
}

table td button.btn-primary {
    min-height: 30px !important;
    min-width: 30px;
    /* width: 30px;*/
    height: 30px;
    display: flex;
    padding: 5px !important;
    align-items: center;
    justify-content: center;
}

table td .btn-primary img {
    width: 100%;
    height: 100%;
}

table tr,
table td {
    vertical-align: middle;
}

section.Company_section .report-header.report-header .load-report .reload-button {
    height: 25px;
    width: 50px;
    padding: 10px;
    background: transparent;
}

section.Company_section .report-header.report-header .load-report .search-button {
    max-width: 105px;
    height: 31px;
    margin-top: 20px;
    border-radius: 6px;
    width: 90px;
}

.reload-button-icon img {
    height: 20px;
}

.search-order-outer {
    display: flex;
    gap: 10px;
}

.map-header {
    position: absolute;
    top: 10px;
    left: 38%;
    transform: translateX(-50%);
    background: #eee;
    padding: 6px;
    margin-bottom: 0;
    border-radius: 5px;
    z-index: 10;
}

.backBTNmap {
    margin-right: 10px;
}

.site-name-overlay {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0;
    border-radius: 5px;
    z-index: 10;
    display: flex;
    align-items: center;
}

.site-name-overlay a.backBTNmap img {
    width: 30px;
}

.site-name-overlay h4 {
    font-size: 18px;
    color: #000;
    margin: 0;
    font-weight: 700;
    background: #eee;
    border-radius: 4px;
    padding: 6px;
}



.search-button-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    color: rgb(255, 255, 255);
    height: 32px;
    width: 30px;
    min-width: 30px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
}

.search-button-icon button {
    background: transparent;
    border: none;
    padding: 0;
}

.search-order-outer .ant-picker {
    height: 32px;
}

.site-time-picker {
    display: block;
    margin-bottom: 10px;
}

.site-time-picker label {
    display: block;
    /* Ensures label is on its own line */
    margin-bottom: 5px;
    /* Space between label and TimePicker */
}

.site-working-days {
    display: block;
    margin-bottom: 10px;
}

.site-working-days label {
    margin-bottom: 10px;
    display: block;
}

.site-working-days .checkbox-list {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    /* Split into 2 equal columns */
    gap: 10px;
    /* Add space between the checkboxes */
}

.site-working-days .checkbox-list {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    /* Default to 2 columns */
    gap: 10px;
    /* Space between checkboxes */
}

.site-working-days .checkbox-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.site-working-days .custom-checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.site-working-days .custom-checkbox input[type="checkbox"] {
    display: none;
    /* Hide native checkbox */
}

.site-working-days .custom-checkbox span {
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    border-radius: 5px;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    transition: background-color 0.3s, border-color 0.3s;
}

.site-working-days .custom-checkbox span::after {
    content: "";
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    position: absolute;
    top: 2px;
    left: 6px;
    transform: rotate(45deg);
    display: none;
}

.site-working-days .custom-checkbox input[type="checkbox"]:checked+span {
    background-color: #BA7B53;
    border-color: #BA7B53;
}

.site-working-days .custom-checkbox input[type="checkbox"]:checked+span::after {
    display: block;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .site-working-days .checkbox-list {
        grid-template-columns: 1fr;
        /* On small screens, display checkboxes in one column */
    }
}

@media (max-width: 480px) {
    .site-working-days .checkbox-list {
        grid-template-columns: 1fr;
        /* Ensure single column on very small screens */
        gap: 5px;
        /* Reduce gap for very small screens */
    }

    .site-working-days .checkbox-item {
        margin-bottom: 8px;
        /* Adjust margin for smaller screens */
    }
}


.reload-button-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    color: rgb(255, 255, 255);
    height: 32px;
    width: 30px;
    min-width: 30px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
}

.timePickerHeader {
    display: flex;
    gap: 20px;
    padding: 0 10px;
    justify-content: center;
}



.time-picker-footer {
    text-align: center;
    margin-top: 10px;
}

.time-label {
    margin: 0 20px;
}

.gap-3 {
    gap: 10px;
}

.soft-module-inner .soft-header .sub-logo img {
    max-width: 140px;
}

.supportRequestLoader {
    height: 8px;
    width: 30px;
}

.ml-auto {
    margin-left: auto;
}

.supportRequestcontainer {
    /*  display: grid;
    grid-template-columns: 2fr 1fr;*/
    display: flex;
    align-items: center;
}

.left-text {
    justify-self: start;
}

.right-text {
    justify-self: end;
}

.calendergroupname {
    padding: 2px 10px;
    background: #fbaf5d;
    min-height: 32px;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    color: #000;
}

smart-scheduler .smart-scheduler-event-content {
    padding: 0 5px !important;
}

smart-scheduler[view-type=month] .smart-scheduler-cells-container .smart-scheduler-cell:not(.scale)[today]>div:not([class]) {
    line-height: 16px;
}

smart-scheduler .smart-scheduler-event .smart-scheduler-event-label {
    margin-top: 1px;
}

/*smart-window#schedulerWindow {
    left: 0 !IMPORTANT;
    top: 0 !important;
    background: #0000006b;
    right: 0 !important;
    bottom: 0 !important;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: none;
    box-shadow: none;
    padding: 20px;
}
    smart-window#schedulerWindow > .smart-container {
        max-width: 500px;
       
    }*/
.calender-log {
    filter: inherit !important;
}

/*media-query-start*/
@media(max-width: 1400px) {
    .soft-module-outer {
        max-width: 25%;
    }
}

@media(max-width: 1200px) {
    .contact_inner::after {
        width: 420px;
        right: -15px;
    }

    .soft-module-outer {
        max-width: 50%;
    }

    nav.navbar.navbar-dark>.container {
        justify-content: end;
    }

    .left_sub h2 {
        font-size: 36px;
    }

    .left_sub>p {
        font-size: 16px;
    }

    .footer_banner::before {
        display: none;
    }

    .left_sub {
        padding: 10px 30px;
    }
}

@media(min-width: 1200px) {
    .container.custom-container {
        max-width: 1250px !important;
    }
}

@media(min-width: 1200px) {
    .container.banner-container {
        max-width: 1200px !important;
    }

    .container.site-container {
        max-width: 1850px !important;
    }

    .container.support-container {
        max-width: 1600px !important;
    }

    .container.company-container {
        max-width: 1300px !important;
    }

    .container.assigned-container {
        max-width: 1600px !important;
    }

    .container.acknowledged-container {
        max-width: 1600px !important;
    }

    .container.usersite-container {
        max-width: 1100px !important;
    }

    .container.notification-container {
        max-width: 1100px !important;
    }
}

@media(min-width: 1200px) {
    .container.project-container {
        max-width: 1100px !important;
    }
}

@media(min-width: 1200px) {
    .container.logs-container {
        max-width: 1300px !important;
    }
}

.container.workitem-container {
    max-width: 100% !important;
}

@media (max-width: 1024px) {
    .dash-icon {
        min-width: 70px;
    }
}

/*@media (max-width: 991px) {
    .dash-icon {
        min-width: 100px;
    }
}*/

@media(max-width: 991px) {

    .dash-icon {
        min-width: 100px;
    }

    .contact_inner::after,
    .right_contact::after {
        display: none;
    }

    ul.navbar-nav .nav-item .nav-link {
        padding: 10px 5px;
        font-size: 13px;
    }

    .support-logos {
        max-width: 180px;
        width: 100%
    }

    .support-logos h5 {
        font-size: 14px;
    }

    .right_contact {
        border-radius: 20px;
        max-width: 100%;
    }

    .contact_list {
        padding: 30px 25px 10px 25px;
    }

    ul.social_icons {
        padding: 20px 25px;
    }


    ul.navbar-nav li:last-child {
        border: none;
    }

    .support_log_head h4 {
        font-size: 20px !important;
    }
}

@media screen and (min-width: 768px) {

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }


    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }


    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

@media screen and (max-width:1200px) {
    ul.navbar-nav .nav-item .nav-link {
        padding: 10px 5px;
        font-size: 13px;
    }
}

@media screen and (max-width:576px) {

    .hide-mobile {
        display: none;
    }

    .ant-pagination .ant-pagination-options {
        display: inline-block;
    }

    .request-tech {
        margin-bottom: 25px
    }

    .tekacontroller-filter div.ml-auto {
        margin-top: 10px;
        margin-left: inherit !important;
        width: 100%
    }

    .tekacontroller-filter div.ml-auto a.d-flex {
        display: block !important;
    }

    .tekacontroller-head {
        justify-content: space-between;
        width: 100%
    }

    .support-badge {
        top: 6px;
        left: 60px;
        right: auto;
    }

    .support-logos {
        margin-bottom: 15px
    }

    .support-logos label,
    .support-logos img {
        margin-bottom: 0 !important
    }

    .request_id {
        justify-content: start !important;
        margin: 10px 0 !important;
    }

    .total_count {
        text-align: left !important;
    }
}


@media screen and (max-width:480px) {
    .ant-pagination .ant-pagination-options {
        display: inline-block;
    }

    .entries-dropdown-search {
        min-width: 100%
    }

    .company_logo {
        display: block;
        text-align: center
    }

    .company_logo .form-label {
        margin-bottom: 15px
    }

    .colors_swatches {
        justify-content: center
    }

    .grouping-div {
        padding: 15px
    }

    .reset-btn-wrap.text-right {
        text-align: center !important;
        margin-top: 10px
    }

    .theme-option.d-flex {
        display: block
    }

    .filter-input.d-flex {
        display: block !important
    }

    .item-filter input,
    .states-drop select,
    .reason-inputs select,
    .other-aspects ul li,
    .states-drop {
        width: 100% !important
    }

    .filter-btns {
        padding-left: 15px
    }

    .more-filter {
        text-align: left !important
    }

    .header-dropdown ul.dropdown-menu.side_profile {
        min-width: auto !important;
    }

    .workitem-container .workitem-heading {
        display: block !important;
    }

    .assigned-section {
        display: block !important;
    }

    .assigned-user.dropdown .dropdown-toggle {
        width: 100% !important;
        margin-bottom: 10px
    }

    .other-aspects ul.d-flex,
    .other-aspects.d-flex {
        display: block !important;
    }


    .settings-container {
        border: 1px solid white;
        padding: 20px 20px;
        border-radius: 5px;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .container-title {
        font-size: 18px;
        margin-bottom: 15px;
        position: absolute;
        top: -15px;
        background-color: rgb(88, 88, 88);
        padding: 0 10px;
        left: 5px;
    }
}

@media screen and (min-width : 380px) and (max-width:425px) {
    .alarm-toggle--wrap {
        margin-right: 20px;
    }
}

@media screen and (max-width: 767px) {
    a.backBTN.back-btn {
        font-size: 0;
    }

    .site-name h4 span {
        font-size: 15px;
    }

    .site-name h4 {
        font-size: 15px;
    }

    .user-group-heading {
        font-size: 18px;
    }

    .soft-alarm {
        width: 50px;
        height: 50px;
    }

    .soft-alarm img {
        max-height: 30px;
    }

    .soft-border-right {
        gap: 10px;
        flex: 1 1;
        padding-right: 10px;
        margin-right: 10px;
        padding-left: 10px;
    }

    .soft-alaram-count h3 {
        font-size: 35px;
    }
}


@media screen and (max-width:320px) {

    .settings-container {
        border: 1px solid white;
        padding: 20px 20px;
        border-radius: 5px;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .container-title {
        font-size: 14px;
        margin-bottom: 15px;
        position: absolute;
        top: -15px;
        background-color: rgb(88, 88, 88);
        padding: 0 10px;
        left: 5px;
    }

    .new-group-form {
        right: -48px;
        width: 290px;
    }
}

@media screen and (min-width: 768px) and (max-width:768px) {
    .settings-container .alarm-toggle--wrap label {
        font-size: 12px;
    }
}

@media screen and (min-width : 769px) {
    .settings-container .alarm-toggle--wrap label {
        font-size: 15px;
    }
}

.carousel-item-content {
    padding: 1rem;
    /* Adjust padding if needed */
}

.support-request .box_1 {
    background: #fff;
    color: #000;
    border-radius: 4px;
    padding: 15px;
    padding-top: 5px;
}

.active-supprot-carosuel .box_1 .heading {
    padding: 2px 15px;
    border-bottom: 1px solid #eee;
    background: #eeeeee87;
}

.active-supprot-carosuel .box_1 .heading .cat-btn {
    min-width: 36px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
}

.support-request .box_1 h5 {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

.support-request .box_1 span {
    width: 40px;
    height: 40px;
    min-width: 40px;
    overflow: hidden;
}

.active-alarm {
    max-width: 1400px;
    margin: auto;
}

.support-request .box_1 span img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.support-request .box_1 .content {
    width: 100%;
}

.support-request .box_1 .content h3 {
    font-weight: 600;
    color: #b97a57;
    line-height: normal;
    margin: 0;
}

.support-request .box_1 .btn-primary.cat-btn {
    margin: 0;
    border-radius: 4px;
    height: 30px;
    min-height: 30px;
    padding: 0 6px;
}

.support-request .box_1 .btn-primary.cat-btn img {
    width: 15px;
    height: 15px;
    object-fit: contain;
}

.active-supprot-carosuel .box_1 {
    border: 1px solid #fff;
    border-radius: 4px;
    position: relative;
    background: #fff;
    color: #000;
    height: 100%;
    /*width: 250px;*/
    width: 100%;
}

.supportgrp-btn:hover {
    color: #fff !important;
}

.active-supprot-carosuel .box_1 h3 {
    font-size: 16px;
    margin: 0 0 0px;
}

.active-supprot-carosuel .box_1 .row {
    row-gap: 15px;
}

.active-supprot-carosuel .box_1 span {
    width: 30px;
    height: 30px;
    min-width: 30px;
    background: #60618c;
    border-radius: 50px;
    position: relative;
}

.active-supprot-carosuel .box_1 span img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50px;
}

.active-supprot-carosuel .box_1 .row {
    row-gap: 15px;
}

.active-supprot-carosuel .box_1 .count-box {
    min-width: 30px;
    text-align: center;
    background: #b97a57;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
}

.active-supprot-carosuel .box_1 .scroll-box {
    max-height: 121px;
    overflow: auto;
    padding: 15px;
}

.active-supprot-carosuel .box_1 .scroll-box .loop-box {
    padding-bottom: 10px;
    margin: 0 0 10px;
    border-bottom: 1px solid #ddd;
}

.active-supprot-carosuel .box_1 .scroll-box .loop-box:last-child {
    margin: 0;
    padding: 0;
    border: none;
}

.active-alarm .bghjHX {
    margin: 0;
}

.active-alarm .bhRnqO {
    height: 25px;
    width: 25px;
    right: -10px;
}

.bhRnqO {
    top: 0 !important;
    bottom: 0;
    margin: auto;
}

.active-alarm .kVtMtA {
    height: 25px;
    width: 25px;
    left: -10px;
}

.time-zone {
    text-align: right;
}

.kVtMtA::before {
    width: 8px;
    height: 8px;
}

.time-zone .clock-wrap {
    background-color: #fff;
    border: 1px solid #474868;
    border-radius: 5px;
    font-family: Courier New, Courier, monospace;
    font-weight: 700;
    padding: 5px 10px;
    text-align: center;
    color: #000;
    display: inline-block;
}

.time-zone .clock-wrap img {
    margin-right: 3px;
    opacity: .4;
    position: relative;
    top: 2px;
    width: 18px;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media (max-width: 1280px) {
    .time-zone .clock-wrap {
        font-size: 11px;
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .time-zone .clock-wrap {
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .time-zone .clock-wrap {
        max-width: 100%;
        font-size: 10px;
    }
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    --smart-scheduler-timeline-cell-min-width: 40px;
    --smart-scheduler-event-border-radius: 4px;
    --smart-scheduler-timeline-nonworking-color: var(--smart-background);
}

#root>div {
    width: 100%;
    height: 100%;
}

.smart-scheduler {
    width: 100%;
    height: 100%;
    border-radius: initial;
}

.center-column {
    text-align: center;
}

#primaryContainer,
#primaryContainer .content {
    width: 100%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
}

#primaryContainer {
    width: 100%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
}

#primaryContainer .content {
    height: calc(100% - 50px);
}

#primaryContainer.collapse #sideA {
    width: 0;
}

#primaryContainer #sideA {
    width: 280px;
    transition: width 0.2s ease-in-out;
}

#primaryContainer.collapse #sideB {
    width: 100%;
    transition: width 0.2s ease-in-out;
}

#primaryContainer #sideB {
    width: calc(100% - 280px);
    transition: width 0.2s ease-in-out;
}

#sideA,
#sideB {
    height: 100%;
    box-sizing: border-box;
}

#header {
    position: relative;
    width: 100%;
    height: 50px;
    background-color: var(--smart-background);
    color: var(--smart-background-color);
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

#sideA {
    border-top: var(--smart-border-width) solid var(--smart-border);
}

#sideA .controls-container {
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    max-height: calc(100% - 150px);
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 100%;
    grid-row-gap: 20px;
    overflow: auto;
}

.button-container {
    height: 150px;
}

#sideA .smart-calendar {
    min-width: initial;
    max-width: 100%;
    height: 250px;
    min-height: initial;
    --smart-font-size: 12px;
    --smart-calendar-cell-size: 26px;
    --smart-calendar-cell-spacing: 4px;
    border: initial;
    --smart-surface: transparent;
}

.smart-input,
.smart-tree {
    width: 100%;
}

.smart-tree {
    max-height: calc(100vh - 247px);
    border: initial;
    height: auto;
    overflow: auto;
}

.smart-tree[show-lines] smart-tree-item,
.smart-tree .smart-tree-item-label-container {
    padding: 1 !important;
}

.smart-tree[toggle-element-position=far] smart-tree-items-group>.smart-tree-item-label-container>.smart-tree-item-label-element {
    margin: 1 !important;
}

.smart-tree-item {
    justify-content: center;
    background: #B97A57;
    color: #fff;
    cursor: pointer;
}

.smart-tree-tech-count {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #B97A57;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 16px;
    z-index: 1;
}

.smart-tree-tech-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
    position: relative;
}

.smart-tree-tech-group-name {
    margin-right: 20px;
}

.smart-tree-tech-icon-style {
    font-size: 24px !important;
    margin-right: 20px !important;
    width: 16px !important;
    height: 16px !important;
}

#primaryContainer.collapse #addNew {
    width: 45px;
    border-radius: 50%;
}

#primaryContainer.collapse #addNew::after {
    left: 0;
    width: 100%;
    height: 100%;
}

#primaryContainer.collapse #addNew span {
    display: none;
}

#addNew {
    top: 100%;
    left: 20px;
    margin-top: 100px;
    position: absolute;
    cursor: pointer;
    width: 120px;
    height: 45px;
    border-radius: 25px;
    box-shadow: var(--smart-elevation-2);
    z-index: 1;
}

#addNew button {
    padding-left: 45px;
}

#addNew::after {
    content: '+';
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 10px;
    width: 30px;
    height: 100%;
    font-family: var(--smart-font-family-icon);
    font-size: 40px;
    color: inherit;
    background: linear-gradient(217deg, rgba(255, 0, 0, .8), rgba(255, 0, 0, 0) 70.71%), linear-gradient(127deg, rgba(0, 255, 0, .8), rgba(0, 255, 0, 0) 70.71%), linear-gradient(336deg, rgba(0, 0, 255, .8), rgba(0, 0, 255, 0) 70.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#toggleButton {
    position: relative;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: initial;
    background: transparent;
    margin-right: 10px;
}

#toggleButton::after {
    content: var(--smart-icon-align-left);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-family: var(--smart-font-family-icon);
    font-size: 25px;
    box-sizing: border-box;
    pointer-events: none;
}

#title {
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    font-size: 20px;
    position: relative;
}

#logo {
    content: '';
    position: relative;
    width: 100%;
    height: 90px;
    background: url(https://www.htmlelements.com/wp-content/design/assets/images/csssprites.png) -465px -400px;
    filter: invert(0.8);
    box-sizing: border-box;
}


/******************  Smart Calendar Started ************************/
.smart-tree[selection-mode="checkBox"] smart-tree-items-group>.smart-tree-item-label-container::before,
.smart-tree[selection-mode="checkBox"] smart-tree-items-group>.smart-tree-item-label-container::after {
    content: initial !important;
}

.smart-tree .smart-tree-item[value="birthday"] {
    --smart-primary: green;
}

.smart-tree .smart-tree-item[value="holiday"] {
    --smart-primary: cornflowerblue;
}

.smart-tree .smart-tree-item[value="event"] {
    --smart-primary: purple;
}

.birthday {
    --smart-scheduler-event-background-rgb: 0, 129, 0;
    --smart-scheduler-event-background: rgba(var(--smart-scheduler-event-background-rgb), 1);
    --smart-scheduler-event-focus: rgba(var(--smart-scheduler-event-background-rgb), .9);
    --smart-scheduler-event-hover: rgba(var(--smart-scheduler-event-background-rgb), .8);
}

.holiday {
    --smart-scheduler-event-background-rgb: 100, 149, 237;
    --smart-scheduler-event-background: rgba(var(--smart-scheduler-event-background-rgb), 1);
    --smart-scheduler-event-focus: rgba(var(--smart-scheduler-event-background-rgb), .9);
    --smart-scheduler-event-hover: rgba(var(--smart-scheduler-event-background-rgb), .8);
}

.event {
    --smart-scheduler-event-background-rgb: 128, 0, 128;
    --smart-scheduler-event-background: rgba(var(--smart-scheduler-event-background-rgb), 1);
    --smart-scheduler-event-focus: rgba(var(--smart-scheduler-event-background-rgb), .9);
    --smart-scheduler-event-hover: rgba(var(--smart-scheduler-event-background-rgb), .8);
}

/******************  Smart Calendar Ended ************************/
#watermark {
    display: none !important;
}

.login-page .contact_inner .form-group .form-control {
    color: #fff !important;
    background: #1F160C !important;
    border: none;
}

.Change_password .input-group .form-control,
.Email_Verification .input-group .form-control {
    background: #1F160C !important;
    border: none;
    color: #fff !important;
}


.active-support {
    background: #fff;
    color: #000;
    border-radius: 4px;
}

.dropdown.group-drop .dropdown-menu li input {
    border: 1px solid var(--smart-border) !important;
}

@media screen and (max-width: 991px) {
    .active-support {
        margin: 0
    }
}

.active-support .head {
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
}

.active-support .head h5 {
    font-size: 14px;
    font-weight: 500;
}

.active-support .head h4 {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.active-support .head h4 span {
    background: #FBAF5D;
    border-radius: 50px;
    color: #fff;
    padding: 2px 10px;
    font-size: 14px;
}

.active-support ul {
    padding: 15px 10px;
    margin: 0;
    list-style: none;
    max-height: 115px;
    overflow: auto;
}

.site-text {
    margin-bottom: 14px;
}

.active-support ul li {
    margin: 0 0 10px;
    padding: 0 0 10px;
    border-bottom: 1px solid #ddd;
    display: flex;
    gap: 10px;
}

.active-support ul li .img-box {
    width: 35px;
    height: 35px;
    overflow: hidden;
    border-radius: 50px;
    min-width: 35px;
    box-shadow: 0 0 0 1px #ddd;
}

.active-support ul li .img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.active-support ul li .content-box {
    width: 100%;
}

.active-support ul li .content-box h5 {
    display: flex;
    font-size: 16px;
    margin: 0 0 4px;
}

.active-support ul li .content-box h5 span {
    margin-left: auto;
    font-size: 14px;
    position: relative;
    top: 2px;
    color: #a8a8a8;
}

.active-support ul li .content-box p {
    margin: 0;
    font-size: 14px;
    color: #a8a8a8;
}

.active-support ul li:last-child {
    margin: 0;
    padding: 0;
    border: none;
}

.support-request,
.active-alarm-slider {
    row-gap: 15px
}

.entries-dropdown-search .form-label {
    color: #000000;
    font-size: 14px;
}

.active-alarm .row {
    row-gap: 25px
}


.dropdown.common-drop .dropdown-menu li a.dropdown-item {
    background-color: unset;
    color: unset;
}

.dropdown.common-drop .dropdown-menu input.form-control {
    border: 1px solid var(--smart-border) !important;
}

.supportgrp-btn svg {
    fill: white !important;
}

.support-site-nav {
    background-color: #f0f0f0;
    padding: 18px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
}

ul {
    list-style-type: none;
}

nav.support-site-nav ul {
    max-height: 130px;
    overflow-x: hidden;
    overflow-y: auto;
}

.support-site-li {
    margin: 0;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
    transition: background-color 0.3s, transform 0.2s;
    color: black;
}

.support-site-li:last-child {
    border: none;
}



.cat-btn-new {
    margin: 0 0 0 10px !important;
    width: 30px;
    height: 30px;
    padding: 0;
    align-items: center;
    display: inline-grid;
    justify-content: center;
    background-color: #B97A57;
    border-color: #B97A57;
    min-height: inherit;
    border-radius: 20px;
}

.supportGroupItem {
    display: inline-block;
    position: relative;
    padding: 4px 8px;
    /* Slightly larger padding */
    border-radius: 8px;
    /* More pronounced border radius */
    color: #BA7B53 !important;
    /* Blue text */
    font-size: 12px;
    /* Increased font size for better visibility */
    font-weight: bold;
    text-decoration: underline;
    /* Underline */
    background: white;
    /* Light blue background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
    transition: background 0.3s;
    /* Smooth background transition */
}

/* Group Calendar css ends */
.dashboard-order-id-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #B97A57;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    font-size: 12px;
    color: white !important;
    text-align: center;
    margin-left: 5px;
}

.description-entry {
    display: block;
    /* Force entries to appear on new lines */
    margin-bottom: 5px;
    /* Space between entries */
}

.smart-calendar {
    max-width: 100% !important;
    width: 100% !important;
}

.clock-wrap {
    border: solid 1px #aeaeae;
    padding: 5px 10px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    border-radius: 5px;
    max-width: 400px;
    text-align: center;
    background-color: #f3f3f3;
}

.clock-wrap img {
    width: 18px;
    position: relative;
    top: 2px;
    margin-right: 3px;
    opacity: .4;
}

.clock-support {
    width: 100%;
    border-left: 3px solid #757070;
}

.time-zone .clock-wrap {
    max-width: 100%;
}

.fallback-heading {
    padding: 35px 20px 0;
}

@media screen and (max-width: 767px) {
    .etbmwA {
        display: block !important;
    }
}

.user-access-level-userdetails {
    font-weight: bold;
    /* Makes the text bold */
    color: #BA7B53;
    /* Changes the text color to a shade of blue */
    font-size: 1.2rem;
    /* Increases the font size slightly */
    background-color: #f8f9fa;
    /* Light gray background */
    padding: 10px;
    /* Adds some padding around the text */
    border-radius: 5px;
    /* Rounds the corners */
    text-align: center;
    /* Centers the text */
}

.user-access-level-icon-circle {
    display: flex;
    /* Use flexbox to align the content */
    align-items: center;
    /* Center vertically */
    justify-content: center;
    /* Center horizontally */
    width: 30px;
    /* Set the width of the circle */
    height: 30px;
    /* Set the height of the circle */
    border-radius: 50%;
    /* Make it circular */
    font-size: 20px;
    /* Set icon size */
    margin: auto;
    /* Center the circle itself if in a flex container */
}

.user-access-level-icon-tick {
    background-color: lightgreen;
    /* Background color for Tick */
    color: green;
    /* Icon color for Tick */
}

.user-access-level-icon-cross {
    background-color: lightcoral;
    /* Background color for Cross */
    color: red;
    /* Icon color for Cross */
}

#Tooltip {
    cursor: pointer;
    position: relative;
    /* Ensure positioning for the tooltip */
    display: inline-block;
    /* Adjust display to inline-block */
}

.priority-indicator {
    display: flex;
    align-items: center;
    background-color: #ff4d4f;
    /* Background color */
    color: white;
    /* Text color */
    border-radius: 5px;
    /* Slightly rounded corners */
    padding: 4px 8px;
    /* Padding for the rectangle */
    margin-left: 8px;
    /* Space between the name and the indicator */
}

.priority-indicator span {
    font-size: 12px;
    /* Font size for the count */
    font-weight: bold;
    /* Bold text for better visibility */
}

/* styles.css */
.idle-modal-header-icon {
    margin: 6px -23px 0px 0px;
}

.idle-modal-header-custom {
    background-color: #BA7B53;
    color: #fff;
    border-bottom: 2px solid #FBAF5D;
    justify-content: left !important;
}

.idle-modal-footer-custom {
    justify-content: space-between;
    border-top: 2px solid #e0e0e0;
}

.idle-modal-button-logout {
    background-color: #FBAF5D;
}

.idle-modal-button-continue {
    background-color: #e0e0e0;
}

.blink-message {
    font-size: 0.8em;
    /* Slightly smaller text */
    font-weight: bold;
    /* Bold text */
    color: #ff0000;
    /* Red color */
    background-color: #fff4f4;
    /* Light red background */
    padding: 10px;
    border-radius: 15px;
    /* Curved corners */
    text-align: center;
    animation: blink 1s step-start infinite;
    /* Blinking effect */
}

.blink-message p {
    margin: 0;
    /* Remove any default margin from <p> */
}

.blink-message p strong {
    color: #ff0000;
    text-decoration: underline;
    /* Underline to make it look like a link */
    /* cursor: pointer; */
}

.blink-message p strong:hover {
    color: #c70000;
    /* Darker red on hover */
    text-decoration: none;
    /* Remove underline on hover */
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

.associated-sites .ant-drawer-body {
    background: #585858;
}

.associated-sites .ant-drawer-header {
    background: #585858;
}

.associated-sites .ant-drawer-title {
    color: #ffffff;
}

.active-alarm-slider smart-scroll-viewer .smart-tree-main-container {
    overflow: auto !important;
    max-height: 200px;
}

.drawer-title-container {
    display: flex;
    align-items: center;
    border: 2px solid #FBAF5D;
    /* Attractive border color */
    padding: 10px;
    border-radius: 8px;
    /* Rounded corners */
    background-color: #FBAF5D;
    /* Soft background color for contrast */
}

.drawer-user-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4A90E2;
    /* Circle color */
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-right: 10px;
}

.drawer-title-text {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    /* Text color */
}

.mob-header-ui {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.scheduler-calendar {
    height: calc(100vh - 90px);
}

.supportcontainer smart-scheduler .smart-scheduler-cell.scale span {
    display: inline;
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


@media (min-width:1024.98px) and (max-width: 1400px) {
    .support-Cont-child {
        width: 30% !important;
        min-width: 30% !important;
    }

    .sidebar-remove .support-Cont-child {
        width: 80px !important;
        min-width: 80px !important;
    }
}

@media (min-width:767.98px) and (max-width: 1024px) {
    .support-Cont-child {
        width: 40% !important;
        min-width: 40% !important;
    }

    .sidebar-remove .support-Cont-child {
        width: 80px !important;
        min-width: 80px !important;
    }
}

@media screen and (max-width:767px) {
    .supportcontainer {
        flex-direction: column !important;
    }

    .support-Cont-child {
        flex-direction: column !important;
        width: 100% !important;
        min-width: 100% !important;
        overflow: visible !important;
    }

    .mob-header-ui {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .subscription-button {
        font-size: 12px !important;
        font-weight: 500 !important;
        padding: 4px 8px !important;
        height: 30px;
    }

    .scheduler-calendar {
        height: calc(100vh - 380px);
    }

    .time-zone .clock-wrap {
        margin-right: 0px;
    }
}

/* Notification Wrapper */
.notification-wrapper {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.icon-container {
    position: relative;
    display: inline-block;
}

/* Notification Count Badge */
.notification-count {
    position: absolute;
    top: -5px;
    right: -9px;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 16px;
    /* Make width and height the same for perfect circle */
    height: 16px;
    font-size: 10px;
    line-height: 16px;
    /* Center text vertically */
    text-align: center;
}

.messagenotification {
    position: absolute;
    top: 9px;
    right: 69px;
    background-color: #ff0c00;
    color: white;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
}


.request-button-css {
    background-color: transparent;
}

.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.support-chat-box.minimized {
    height: 50px;
    /* Adjust the minimized height */
    width: 250px;
    /* Adjust width to fit the minimized view */
    overflow: hidden;
}

.setting-dropdown-menu .dropdown-menu {
    padding: 10px;
    color: #605D5D;
}



/* Notification Dropdown List */
.notification-list {
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    background: #fff;
    border: 2px solid #ff4757;
    /* Red border for the top of the modal */
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    width: 300px;
    padding: 15px;
    z-index: 1000;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Show the notification list with animation */
.notification-wrapper:hover .notification-list,
.notification-wrapper .notification-list-active {
    opacity: 1;
    transform: translateY(0);
}

/* Notification Header */
.notification-header {
    background-color: #BA7B53;
    /* Set background color */
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
}

.notification-header h4 {
    color: #fff;
    margin: 0;
}

/* Notification Item */
.notification-item {
    display: flex;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid #f0f0f0;
    font-size: 14px;
    color: #333;
    position: relative;
    transition: background-color 0.3s ease;
}

.notification-item:last-child {
    border-bottom: none;
}

/* Icon styling */
.notification-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

/* Text Styling */
.notification-text {
    flex: 1;
}

/* Main Message Styling */
.notification-item span:first-child {
    font-size: 14px;
    color: #333;
    padding-right: 10px;
}

/* Date Styling */
.notification-date {
    font-size: 10px;
    color: #aaa;
    font-weight: 400;
    position: absolute;
    bottom: 4px;
    right: 0;
}

/* Hover Effect on Notification Item */
.notification-item:hover {
    background-color: #f9f9f9;
    border-radius: 4px;
}

/* Footer Styling as Button */
.notification-footer {
    background-color: #BA7B53;
    /* Color for the footer */
    padding: 15px;
    width: 100%;
    border-radius: 8px;
    text-align: center;
    font-size: 14px;
    color: #fff;
    margin-top: 15px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

/* Hover Effect on Footer */
.notification-footer:hover {
    background-color: #a56f45;
    /* Darker shade on hover */
}

/* Assiging Support Fallback */
/* Basic styling for the panel */
.fallback-panel {
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    color: #fff;
    gap: 10px;
}

.fallback-panel h3 {
    margin: 0;
    color: #fff;
    font-size: 24px;
    flex: 1 1;
    border-right: 1px solid #ccc;
    padding-right: 10px;
}


.fallback-dropdown label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.fallback-dropdown select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
}

.fallback-assign-btn {
    padding: 6px 12px;
    font-size: 16px;
    color: #fff;
    background-color: #BA7B53;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.add-support-icon {
    color: #fff;
    height: 40px;
    width: 40px;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
}

.custom-modal-size .modal-dialog {
    width: 100%;
    max-width: 1150px;
    /* Adjust width (90% of the viewport width) */

}

.fallback-assign-btn:hover {
    background-color: #B97A57;
}

.fallback-info {
    display: flex;
    align-items: center;
    /* Align items vertically in the center */
}

.fallback-name {
    font-size: 16px;
    font-weight: bold;
    color: white;
    margin-right: 10px;
}

.fallback-edit-btn {
    padding: 6px 12px;
    font-size: 14px;
    color: #fff;
    background-color: #BA7B53 !important;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.fallback-edit-btn:hover {
    opacity: 90%;
}

.phnumber .react-tel-input input {
    width: 100%;
    padding: 0;
    padding-left: 55px;
    /* height: 32px; */
}

.addMedia .img-section .imgbox {
    width: 30%;
}

.qrBtn {
    width: 60%;
}

.add-support-modal textarea.form-control {
    font-size: 16px;
    background: #FFFFFF;
    border: 1px solid #CACACA !important;
    border-radius: 5px !important;
    height: 42px;
}

.add-support-model .ant-select-selector {
    height: 42px !important;
}

.add-support-model .ant-select-selector input {
    height: 42px !important;
}

.add-support-model .ant-select-selector span.ant-select-selection-placeholder,
.add-support-model .ant-select-selector span.ant-select-selection-item {
    display: flex;
    align-items: center;
}

.support-order,
.support-assign {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0;
    color: #000 !important;
    background: transparent;
    position: relative;
}

.support-order svg,
.support-assign svg {
    font-size: 18px;
}

.divide-box {
    display: flex;
    align-items: center;
    gap: 13px;
    background: #e0e0e0;
    padding: 5px 10px;
    border-radius: 6px;
}

.divide-box .support-order::after {
    position: absolute;
    right: -8px;
    bottom: 0;
    top: 0;
    background: #b2b2b2;
    height: 100%;
    width: 1px;
    content: '';
}

.dashboard-today-text {
    color: #B97A57;
    /* Example color */
    font-weight: bold;
    /* Example bold text */
    font-style: italic;
    /* Example italic style */
}

.dashboard-time-slot-container {
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
}

.dashboard-time-slot-container .dashboard-time-zone,
.dashboard-time-slot-container {
    margin-bottom: 2px;
    color: #333;
    font-size: 12px;
}

.dashboard-available-slot {
    margin-bottom: 2px;
    color: #333;
    font-size: 12px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px 0px 3px 5px;
}

.dashboard-week-days {
    margin-left: 8px;
    /* Space between time and days */
    /*display: inline-flex;*/
    /* Keeps days in one line */
    gap: 4px;
    /* Small spacing between days */
    font-size: 10px;
    /* Tiny size for weekdays */
    color: #666;
    /* Slightly lighter color for distinction */
    background: #BA7B53;
    cursor: pointer;
}

.dashboard-week-days .day {
    padding: 2px 4px;
    /* Optional: Add padding for clarity */
    border-right: 1px solid #FFFFFF33;
    border-radius: 2px;
    /* Optional: Round corners */
    color: #fff;
}


.dashboard-time-slot-container strong {
    color: #BA7B53;
}

.site-daylight-margin {
    margin-top: 2.5rem !important;
}

.timezone-label {
    font-weight: normal;
    font-size: 12px;
    color: #777;
    margin: 0;
}


.support-technicians-tabs .nav-tabs {
    width: 100%;
    border: none;
}

.support-technicians-tabs .nav-tabs li+li {
    margin-left: 1px;
}

.support-technicians-tabs .nav-tabs .nav-link {
    background: #696969;
    padding: 9px 8px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    border-radius: 4px 4px 0 0;
    border: none;
}

.support-technicians-tabs .nav-tabs .nav-link.active,
.support-technicians-tabs .nav-tabs .nav-item.show .nav-link {
    color: #FFFFFF;
    font-weight: 500;
    background-color: #BA7B53;
    border: none;
}

.support-technicians-tabs .tab-content {
    background: #696969;
    border-radius: 0 4px 4px;
    padding: 5px 4px;
    overflow: auto;
    height: calc(100vh - 170px);
}

.setting-dropdown-menu button::after {
    display: none;
}

.setting-dropdown-menu button {
    width: 32px;
    height: 32px;
    border: 1px solid #FFFFFF4D;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
}

.setting-dropdown-menu button:hover {
    border-color: #fff;
}

.support-header-right {
    display: flex;
    gap: 5px;
}

.setting-dropdown-menu .dropdown-menu {
    padding: 10px;
    color: #605D5D;
}

.setting-dropdown-menu .dropdown-menu li {
    margin-bottom: 14px;
}

.setting-dropdown-menu .dropdown-menu li:last-child {
    margin: 0;
}

@media screen and (max-width: 767px) {
    .support-technicians-tabs .tab-content {
        height: auto;
        max-height: 300px;
    }

    .support-Cont-child h4 {
        font-size: 20px;
    }
}

.sup-workItem-container {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 5px;
}

.sup-workItem-highlight {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sup-workItem-highlight:hover {
    background-color: #ffe6cc;
    /* Lighter coral shade for background on hover */
    color: #fbaa5d;
    /* Coral shade for text on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Slightly stronger shadow on hover */
    cursor: pointer;
}

.sup-workItem-icon {
    margin-right: 5px;
    color: #fbaa5d;
    /* Coral shade for the icon */
    font-size: 18px;
    /* Adjust size as needed */
}

.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.add-support-model .fileUploader .fileContainer {
    margin: 0;
    padding: 0;
    box-shadow: none;
}

.add-support-model .fileUploader {
    margin: 20px 0 0;
}

.add-support-model .fileUploader .fileContainer {
    margin: 0;
    padding: 0;
    box-shadow: none;
}

.add-support-model .fileUploader {
    margin: 20px 0 0;
}

.add-support-model .fileUploader .uploadPicturesWrapper {
    margin: 20px 0 0;
}

.modal-loader-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.add-support-model .fileUploader .uploadPicturesWrapper .uploadPictureContainer {
    margin: 0;
    box-shadow: none;
}


.add-support-model .fileUploader .uploadPicturesWrapper .uploadPictureContainer .deleteImage {
    font-size: 13px;
    background: #ea0000;
    width: 25px;
    height: 25px;
    padding: 0;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* chat box css start */
.chat-box-outer {
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    z-index: 1000;
    gap: 15px;
}

.support-chat-box {
    width: 350px;
    height: 400px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.chatbox-enter {
    opacity: 1;
    transform: translateX(0);
}

.chatbox-exit {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.chatbox-header {
    background: #BA7B53;
    display: flex;
    justify-content: space-between;
    padding: 5px 15px;
    align-items: center;
    border-radius: 8px 8px 0 0;
    gap: 5px;
}

.chat-header-avatar {
    height: 40px;
    width: 40px;
    overflow: hidden;
    border-radius: 100%;
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #995e39;
    font-weight: bold;
}

.chat-name {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.chat-name-inner {
    flex: 1;
}

.chat-name-inner h4 {
    font-size: 15px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    margin: 0;
}

.chat-name-inner span {
    font-size: 12px;
    position: relative;
    display: flex;
    gap: 6px;
    align-items: center;
    line-height: 15px;
}

.green-circle {
    width: 6px;
    height: 6px;
    background: #35C00B;
    border-radius: 100%;
}

.chat-btns {
    display: flex;
    gap: 4px;
}

.chat-btn-inner {
    background: rgba(255, 255, 255, 0.15);
    width: 26px;
    height: 26px;
    border-radius: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.chat-btn-inner:hover {
    background-color: #fbaf5d;
}

.chat-footer {
    padding: 6px;
    width: 100%;
    position: relative;
    box-shadow: 0px -6px 20px 0px #0000000A;
    min-height: 52px;
}

.chat-submit-btn {
    background: #000;
    border-radius: 4px;
    height: 30px;
    width: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 6px;
    bottom: 6px;
}

.send-message {
    max-width: 70% !important;
}

.chat-submit-btn:hover {
    opacity: 0.8;
}

.send-img {
    max-width: 70%;
}

.chat-footer input {
    width: 100%;
    height: 100%;
    box-shadow: none;
    outline: none;
    font-size: 15px;
    padding-left: 10px;
    padding-right: 52px;
}

.chat-inner {
    flex: 1 1;
    overflow-y: auto;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: #eeeeee42;
}

.chat-user {
    display: flex;
    gap: 10px;
    align-items: flex-end;
}

.chat-user-avatar {
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 100%;
}

.chat-user .chatInner {
    box-shadow: 0px 6px 10px 0px #ECF4F6;
    padding: 6px 12px;
    color: #000;
    font-size: 13px;
    border-radius: 12px 12px 12px 0;
    line-height: 19px;
    background: #ffffff;
    padding-right: 10px;
}

.typing-indicator {
    color: #cbc0c0;
    font-size: 14px;
    padding-left: 20px;
}

.chat-user-odd {
    display: flex;
    gap: 10px;
    flex-direction: row-reverse;
    align-items: flex-end;
    padding-left: 10px;
}

.chat-user-odd .chatInner {
    box-shadow: 0px 6px 10px 0px #ECF4F6;
    padding: 6px 12px;
    color: #fff;
    font-size: 13px;
    border-radius: 12px 12px 0 12px;
    line-height: 19px;
    background: #FBAF5D;
}

.chat-user-avatar img,
.chat-header-avatar img {
    width: 100%;
    height: 100%;
}

.video-modal {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.video-modal-center {
    position: relative;
    width: 100%;
    max-width: 1000px;
    background: #fff;
    padding: 16px;
    border-radius: 16px;
}

@media screen and (max-width: 400px) {
    .support-chat-box {
        width: 280px;
    }

    .chatbox-header {
        padding: 5px 9px;
    }

    .chat-btn-inner {
        width: 24px;
        height: 24px;
    }
}

/* Videocall Css */
.video-modal {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.video-modal-center {
    position: relative;
    width: 100%;
    max-width: 1000px;
    background: #fff;
    padding: 16px;
    border-radius: 16px;
}

@media screen and (max-width: 400px) {
    .support-chat-box {
        width: 280px;
    }

    .chatbox-header {
        padding: 5px 9px;
    }

    .chat-btn-inner {
        width: 24px;
        height: 24px;
    }
}

/* chat box css end */




@media screen and (max-width: 767px) {
    .support-technicians-tabs .tab-content {
        height: auto;
        max-height: 300px;
    }

    .support-Cont-child h4 {
        font-size: 20px;
    }
}


/* vido call css */
.controls-video {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.video-call-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    padding: 20px;
    align-items: center;
    z-index: 1000;
    /* Ensure it overlays other content */
    transition: all 0.3s ease;
}

.video-call-overlay.minimized {
    top: auto;
    left: auto;
    right: 20px;
    bottom: 20px;
    width: auto;
    height: auto;
    background-color: transparent;
    /* Remove background overlay */
    justify-content: flex-end;
    align-items: flex-end;
}

.video-call-modal {
    background-color: #fff;
    width: 100%;
    max-width: 925px;
    height: 400px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    height: 100%;
    max-height: 700px;
}

.video-call-overlay.minimized .video-call-modal {
    width: 300px;
    height: 200px;
    max-width: 300px;
    max-height: 200px;
    padding: 5px;
}

.video-call-overlay.minimized .local-video {
    display: none;
}

.video-container {
    height: 100%;
    position: relative;
    width: 100%;
    background: #f1f1f1;
    border: 1px dashed #ccc;
    color: #888;
}

.remote-video {
    width: 100%;
    height: 100%;
    background: #eee;
    object-fit: cover;
}

.video-call-buttons {
    display: flex;
    gap: 20px;
}

.video-call-container {
    position: relative;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.local-video {
    position: absolute;
    right: 30px;
    bottom: 30px;
    width: 171px;
    height: 129px;
    overflow: hidden;
    background: #aaa;
    border: 1px solid #fff;
    border-radius: 8px;
    z-index: 1;
}

.user-full-name {
    font-size: 14px;
    top: 10px;
    position: absolute;
    left: 10px;
    text-shadow: 0px 1px 11px black;
}

.local-video video {
    width: 100%;
    height: 100%;
}

.accept-call-btn,
.end-call-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    color: #fff;
}

.controls {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
}

.accept-call-btn {
    background-color: #4caf50;
    /* Green for accepting */
}

.end-call-btn {
    background-color: #f44336;
    /* Red for ending */
}

.accept-call-btn:hover {
    background-color: #45a049;
}

.end-call-btn:hover {
    background-color: #e53935;
}

.incoming-call {
    width: 100%;
    position: relative;
    text-align: center;
    padding: 0 20px 20px;
}

.incoming-call::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
    background: #8B5A3D;
    width: 100%;
    left: 0;
}

.incoming-call h4 {
    font-size: 22px;
    margin-bottom: 8px;
    color: #000000;
}

.incoming-call h6 {
    font-size: 16px;
    color: #575757;
    margin-bottom: 22px;
}

.incoming-call h6 strong {
    color: #B97A56;
    font-weight: 400;
}

.call-btns {
    display: inline-flex;
    gap: 16px;
}

.call-btns .btn {
    min-width: 95px;
}

.call-btns .btn-primary {
    background-color: #8B5A3D;
    border-color: #8B5A3D;
}

.call-btns .btn-bordered {
    border-color: #8B5A3D !important;
    color: #8B5A3D !important;
}

.call-btns .btn-bordered:hover {
    background-color: #8B5A3D !important;
    color: #fff !important;
}

.MuiPaper-root:has(.incoming-call) {
    border-radius: 10px;
    width: 100%;
    max-width: 440px;
}

.avatar-incoming {
    width: 128px;
    height: 128px;
    border-radius: 100%;
    border: 3px solid #fff;
    box-shadow: 0px 6px 12px 0px #0000001A;
    margin-top: 30px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    margin-bottom: 20px;
    overflow: hidden;
}

.avatar-incoming img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.window-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
}

.control-buttons {
    display: flex;
    gap: 8px;
}

.videocall-header-avatar span {
    color: black;
    font-size: 21px;
}

.display-name {
    display: flex;
    align-items: center;
    gap: 8px;
}

.videocall-header-avatar {
    height: 33px;
    width: 37px;
    overflow: hidden;
    border-radius: 100%;
    /* min-width: 35px; */
    display: flex;
    align-items: center;
    justify-content: center;
    background: #995e39;
    font-weight: bold;
}

.local-video-container {
    position: relative;
}

.local-video-overlay {
    position: absolute;
    bottom: 10px;
    /* Adjust to move the text vertically */
    left: 10px;
    /* Adjust to move the text horizontally */
    background-color: rgba(0, 0, 0, 0.6);
    /* Semi-transparent background */
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    /* Small text */
}

.user-full-name {
    font-weight: bold;
}

.MuiAvatar-root.MuiAvatar-circular.Avatar-img.css-lrbmwt-MuiAvatar-root {
    border: 1px solid black;
    padding: 10px;
}

.controlButtons {
    background-color: #FBAF5D !important;
    height: 30px !important;
    width: 30px !important;
    color: black !important;
}

.connectionId span {
    color: black !important;
    font-size: 16px !important;
}

/* Shaking Animation */
.calling-template {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
}

.calling-animation {
    position: relative;
    width: 80px;
    height: 80px;
    margin-top: 20px;
}

.ripple {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 0;
    border-radius: 50%;
    background: #FBAF5D;
    opacity: 0.3;
    animation: ripple 1.5s infinite ease-in-out;
}

@keyframes ripple {
    0% {
        transform: scale(0.3);
        opacity: 1;
    }

    100% {
        transform: scale(3);
        opacity: 0;
    }
}

.caller-image {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 100%;

}

.caller-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;
}