/* General Reset and Container Styling */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.subscription-page {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    text-align: center;
}

/* Title Styling */
.title {
    font-size: 36px;
    font-weight: bold;
    color: #BA7B53;
    margin-bottom: 20px;
    border-bottom: 2px solid #BA7B53;
    padding-bottom: 5px;
}

.subtitle {
    font-size: 20px;
    color: #fff;
    background-color: #4a90e2;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.description {
    font-size: 16px;
    margin: 10px auto 30px;
    line-height: 1.6;
    color: #ffff;
    max-width: 800px;
}

/* Plan Cards Container */
.plans {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
}

.plan {
    background: #fff;
    border-radius: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 23%;
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

    .plan:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }

/*.best-package {
    background: linear-gradient(135deg, #d0ebff, #ffffff);
    border: 2px solid #2d6cdf;
}*/

.plan h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.plan h3 {
    font-size: 14px;
    color: #2d6cdf;
    font-weight: bold;
    margin-bottom: 5px;
}

.price {
    font-size: 20px;
    font-weight: bold;
    color: #2d6cdf;
    margin-bottom: 5px;
}

.monthly-cost {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin: 10px 0;
}

    .monthly-cost span {
        font-size: 16px;
        color: #777;
    }

.billed, .term, .access {
    font-size: 14px;
    color: #777;
    margin-top: 5px;
}

.access {
    font-weight: bold;
    margin-top: 15px;
}

.plan ul {
    list-style-type: none;
    padding: 0;
    margin: 15px 0;
    line-height: 1.6;
    width: 100%;
    text-align: left;
}

.plan li {
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #333;
}

    .plan li::before {
        content: '✓';
        color: green;
        font-weight: bold;
        margin-right: 5px;
    }

.select-button {
    background-color: #BA7B53;
    color: #fff;
    border: none;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    width: 100%;
    max-width: 200px;
    margin-top: auto;
}

    .select-button:hover {
        background-color: #1e4ca3;
        transform: scale(1.05);
    }

/* Footer Styling */
.footer {
    font-size: 12px;
    color: #777;
    margin-top: 40px;
    line-height: 1.6;
}

    .footer .phone-number {
        color: #2d6cdf;
        font-weight: bold;
    }

/* Responsive Styling */
@media (max-width: 992px) {
    .plan {
        width: 48%;
    }
}

@media (max-width: 600px) {
    .plan {
        width: 100%;
    }

    .title {
        font-size: 28px;
    }

    .subtitle {
        font-size: 18px;
    }

    .description {
        font-size: 14px;
    }
}

.plan.best-package h3 {
    background-color: #BA7B53; 
    color: #fff;
    font-weight: bold;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 18px;
    top: 10px;
    left: 10px;
    z-index: 10;
}

.selected-plan {
    background: linear-gradient(135deg, #d0ebff, #ffffff);
    border: 2px solid #2d6cdf;
}

.select-button:disabled {
    background-color: #e0e0e0; /* Light gray background for disabled button */
    color: #b0b0b0; /* Light gray text color */
    border: 1px solid #b0b0b0; /* Gray border */
    cursor: not-allowed; /* Change the cursor to indicate it's disabled */
}

.subscription-button {
    background: linear-gradient(45deg, #ff7e5f, #feb47b); /* Gradient background */
    color: white;
    font-size: 18px; /* Larger font size */
    font-weight: bold;
    padding: 10px 25px; /* Padding to make the button bigger */
    border: none;
    border-radius: 30px; /* Rounded corners */
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    display: inline-flex; /* Aligns icon and text on the same line */
    align-items: center; /* Vertically aligns the icon with text */
    justify-content: center; /* Centers both icon and text */
}

    .subscription-button i {
        margin-right: 10px; /* Space between icon and text */
        font-size: 20px; /* Adjust icon size */
    }

    .subscription-button:hover {
        background: linear-gradient(45deg, #ff6a00, #ee0979); /* Darker gradient on hover */
        transform: translateY(-5px); /* Hover lift effect */
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); /* Shadow on hover */
    }

    .subscription-button:active {
        background: linear-gradient(45deg, #ee0979, #ff6a00); /* Reversed gradient on click */
        transform: translateY(0px); /* Remove lift effect when clicked */
        box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.1); /* Softer shadow when clicked */
    }

    .subscription-button:focus {
        outline: none; /* Remove outline */
    }

/* Feedback button styles */
.feedback-button {
    background-color: #585858; /* Blue color */
    color: white;
    font-size: 15px; /* Same font size as subscription button */
    font-weight: bold;
    padding: 5px 8px; /* Rectangular padding */
    border: none;
    border-radius: 5px; /* Slightly rounded corners */
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    display: inline-flex; /* Aligns icon and text on the same line */
    align-items: center; /* Vertically aligns the icon with text */
    justify-content: center;
}