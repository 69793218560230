   /* parts  */
   .relative-box {
    position: relative;
}

.bordered-title {
    position: absolute;
    top: -10px;
    left: 20px;
    background: #047CB4;
    color: #000;
    padding: 0 10px;
    z-index: 1;
}

.title-text {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

.assign-shape-box {
    border: 1px solid black;
    margin: 35px 0 0;
    /* max-height: calc(100vh - 290px); */
    /* overflow: auto; */
    padding: 30px 15px;
    position: relative;
    height: 400px;
    overflow-y: auto;
}
.partname-text{
     margin: 0;
    font-size: 18px;
    font-weight: 600;
    color:#a56f45
}


.parts-pagination {
 display: flex ;
 justify-content: end;
 margin: 16px;
}

.sidebar{ 
    padding : 26px; 
}

.sidebar_menu{
    height: 50%;
    min-height: fit-content;
    border-radius: 10px;
    width: fit-content;
}

.context_area_heading {
  flex: 1;
  padding: 26px;
  margin: 0px 24px;
 overflow-y: auto;
}
.header-section{
    display: flex;
    justify-content:space-between;
    align-items :center ;
}
.parts_img{
    width: 30px;
    height: 30px;
    margin-right:10px;
    border-radius : 50%;
    object-fit: cover; 
}
.action_buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.default_error_text{
    text-align: center;
    padding: 20px;
    color : gray;  
}

.parts_table_image{
    width: 40px;
    height: 40px ;
    margin-right: 40px;
}

.selectedpart_image {
    width: 150px;
    height: 150px ;
    object-fit : cover;
    border-radius : 8px,
}

select.form-select-parts.w-100 {
    padding: 10px;
    border: 1px solid #E2E1E1;
    border-radius: 4px;
}

.parts-drawer .ant-drawer-header {
    background: #585858;
}
.parts_drawer.h3{
    color: #ffff;
}
.parts-drawer .ant-drawer-body {
    background: #585858;
}
.parts-drawer .ant-list-item-meta {
    background: white;
    border-radius: 8px;
    padding: 8px;
}
/* Add these styles to make the container scrollable */
.infinite-scroll-component {
    overflow: hidden !important;
}
