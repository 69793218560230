.support-technician-search-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Space below the container */
}

.support-technician-search-input {
    padding: 5px; /* Input padding */
    flex: 1; /* Make input take up available space */
}

.support-technician-add-button {
    margin-left: 10px; /* Space between input and button */
    background-color: #BA7B53
}
