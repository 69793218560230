/* This is the CSS used in the demo */
html,
body {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

#root > div {
    width: 100%;
    height: 100%;
}

.smart-scheduler {
    width: 100%;
    height: 100%;
    --smart-scheduler-event-size-basic: 35px;
    --smart-scheduler-timeline-cell-width: 50px;
    --smart-scheduler-timeline-cell-min-width: 50px;
    --smart-scheduler-timeline-header-horizontal-group-size: 125px;
    --smart-scheduler-timeline-all-day-label-font-weight: 300;
    --smart-scheduler-timeline-header-cell-padding: 1px;
    /* Client variables */
    --employee-1-rgb-color: 59, 7, 72;
    --employee-2-rgb-color: 95, 54, 141;
    --employee-3-rgb-color: 5, 22, 61
}

.smart-scheduler {
    --smart-scheduler-timeline-weekend-color: rgba(var(--employee-1-rgb-color), 0.2);
    --employee-rgb-color: var(--employee-1-rgb-color);
    --smart-scheduler-event-background-rgb: var(--employee-1-rgb-color);
    --smart-scheduler-event-background: rgba(var(--smart-scheduler-event-background-rgb), 1);
    --smart-scheduler-event-focus: rgba(var(--smart-scheduler-event-background-rgb), .9);
    --smart-scheduler-event-hover: rgba(var(--smart-scheduler-event-background-rgb), .8);
}

    /* CellTemplate styles */

    .smart-scheduler .gym {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        padding-right: 2.5px;
        background-repeat: no-repeat;
        background-position: center;
    }



    /* GroupTemplate Styles */

    .smart-scheduler .resource-content {
        display: grid;
        grid-template-columns: auto minmax(0, 1fr);
        width: 100%;
        height: 100%;
        background: rgba(var(--employee-rgb-color), 1);
    }

        .smart-scheduler .resource-content .avatar {
            padding: 0 10px 0 10px;
            height: 100%;
            display: flex;
            justify-content: center;
        }

            .smart-scheduler .resource-content .avatar::before {
                content: '';
                background-repeat: no-repeat;
                background-size: auto;
                display: flex;
                width: 100px;
                height: 100px;
                border-radius: 0px;
                background-position: center;
                background-size: cover;
                box-shadow: 0 0 3px 3px inset rgba(var(--employee-rgb-color), 1);
            }

        /*.smart-scheduler [clientId="1"] .resource-content .avatar::before {
        background-image: url('https://authentekstorage.blob.core.windows.net/tekalog-dev-container/San.jpg');
    }

    .smart-scheduler [clientId="2"] .resource-content .avatar::before {
        background-image: url('https://authentekstorage.blob.core.windows.net/tekalog-dev-container/San.jpg');
    }

    .smart-scheduler [clientId="3"] .resource-content .avatar::before {
        background-image: url('https://authentekstorage.blob.core.windows.net/tekalog-dev-container/San.jpg');
    }*/

        .smart-scheduler .resource-content > div,
        .smart-scheduler .resource-content .info,
        .smart-scheduler .resource-content .name {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .smart-scheduler .resource-content > div {
            padding: 0 10px 0 10px;
        }

        .smart-scheduler .resource-content .info,
        .smart-scheduler .resource-content .name {
            align-items: flex-start;
        }

        .smart-scheduler .resource-content .info {
            color: white;
        }

        .smart-scheduler .resource-content .name {
            color: white;
            font-weight: 400;
            font-size: 28px;
        }

#watermark {
    display: none !important;
}

.smart-scheduler-disabled {
    opacity: inherit !important;
}

.event-tooltip {
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 15px; /* Increased padding for larger tooltip */
    border-radius: 10px; /* More rounded corners */
    font-size: 14px; /* Increased font size */
    display: none; /* Hidden by default */
    z-index: 1000;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Slightly larger shadow */
    width: 300px; /* Set a fixed width for the tooltip */
    padding-left: 40px; /* Space for icon */
}

    /* Show tooltip when active */
    .event-tooltip.visible {
        display: block;
    }

    /* Arrow styles */
    .event-tooltip::after {
        content: '';
        position: absolute;
        bottom: 100%; /* Position above the tooltip */
        left: 50%; /* Center the arrow */
        margin-left: -6px; /* Center the arrow */
        border-width: 6px;
        border-style: solid;
        border-color: transparent transparent #333 transparent; /* Arrow color */
    }

    /* Icon using a background image */
    .event-tooltip::before {
        content: '';
        position: absolute;
        left: 10px; /* Position the icon */
        top: 50%; /* Center vertically */
        transform: translateY(-50%); /* Adjust for vertical centering */
        width: 20px; /* Set the icon width */
        height: 20px; /* Set the icon height */
        background-image: url('./assets/images/customer-support.png'); /* Correct path */
        background-size: contain; /* Ensure the icon fits */
        background-repeat: no-repeat; /* Prevent repeating */
    }

.support-technicians {
    display: flex;
    align-items: center;
    cursor: pointer;
}


.support-technicians-checkbox {
    margin-right: 8px;
    cursor: pointer;
}

.support-technicians-task {
    padding: 6px; /* Padding inside each task item */
    margin-bottom: 10px; /* Margin between task items */
    border-radius: 5px; /* Rounded corners for task items */
    color: #fff; /* Text color (white) */
    font-weight: bold; /* Bold font for task labels */
    cursor: grab; /* Change cursor to grab hand when hovering over task item */
    display: flex; /* Ensure flex behavior if needed */
    align-items: center; /* Center items vertically */
    transition: transform 0.2s ease; /* Smooth transition */
}

    .support-technicians-task:hover {
        transform: scale(1.02); /* Scale up on hover */
    }

.support-technician-details {
    display: flex;
    flex-direction: column; /* Stack Order ID and Support Group vertically */
    margin-left: 10px; /* Add space between technician name and details */
    background-color: #f0f0f0; /* Light background color for contrast */
    border-radius: 8px; /* Slightly curved corners */
    padding: 5px 6px; /* Padding for spacing inside the div */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
    margin-bottom: 10px;
}


.support-technicians-toggleButton {
    display: flex; /* Enable flexbox */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    padding: 5px; /* Padding inside the button */
    background-color: transparent; /* Transparent background */
    color: #fff; /* Text color for the button */
    border: 1px solid #FFFFFF4D; /* Solid white border */
    border-radius: 4px; /* Rounded corners for the button */
    cursor: pointer; /* Pointer cursor on hover */
    width: 32px;
    height: 32px;
}

    .support-technicians-toggleButton:hover {
        border-color: #fff;
    }

.support-small-time {
    font-size: 0.8em; /* Adjust size as needed */
}

.support-Orderid-circle {
    width: 15px; /* Size of the circle */
    height: 15px; /* Size of the circle */
    border-radius: 50%; /* Makes it circular */
    display: inline-flex; /* Aligns content in the center */
    align-items: center; /* Center the content vertically */
    justify-content: center; /* Center the content horizontally */
    color: white; /* Text color inside the circle */
    font-size: 0.8em; /* Adjust size as needed */
}

.support-GroupName {
    font-size: 0.8em; /* Adjust size as needed */
}

.support-TimeZone {
    padding: 0px 13px 0px 0px;
    font-size: 0.8em; /* Adjust size as needed */
}

.support-order-id-label {
    font-size: 0.8em; /* Adjust size as needed */
    margin-right: 5px;
}

.event-content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column; /* Stack items vertically */
}

.support-order-no-container {
    display: flex;
    align-items: center; /* Center items vertically */
    margin-bottom: 5px; /* Add spacing */
}

.support-small-time, .desc, .support-GroupName {
    text-align: left; /* Ensure left alignment for these elements */
}

.support-technician-name {
    font-weight: bold;
    border: 1px solid #ddd; /* Softer gray border for a cleaner look */
    background-color: #f4f6f9; /* Slightly darker background for better contrast */
    padding: 6px 12px; /* Increased padding for better spacing */
    border-radius: 6px; /* More rounded corners */
    color: #333; /* Darker text for better readability */
    font-size: 12px; /* Slightly larger font for better readability */
    line-height: 1.5; /* Improved line height for better text spacing */
    border-left: 5px solid #007bff; /* Slightly thicker border on the left for emphasis */
    cursor: pointer;
    transition: all 0.3s ease-in-out; /* Smooth transition on hover */
}

    .support-technician-name:hover {
        background-color: #e0e7ff; /* Light blue background on hover */
        border-left-color: #0056b3; /* Darker blue left border on hover */
    }

    .support-technician-name:active {
        background-color: #d1d8e0; /* Slightly darker background on active click */
        border-left-color: #003f8a; /* Darker blue left border when clicked */
    }


.order-id, .support-group {
    font-size: 12px; /* Adjust font size */
    color: #555; /* Lighter color for details */
    width: 100px;
}

/* Group Calendar css starts */
.smart-scheduler .custom-group-content {
    display: flex;
    align-items: center;
    grid-column-gap: 10px;
}

    .smart-scheduler .custom-group-content .details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        grid-row-gap: 5px;
    }

        .smart-scheduler .custom-group-content .details .name {
            font-size: 18px;
        }

        .smart-scheduler .custom-group-content .details .speciality {
            font-weight: 400;
        }

.smart-scheduler .header-day-number {
    font-size: 18px;
}
/* Group Calendar css ends */
.support-order-id-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #f0f0f0; /* Light background color */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px; /* Space between circle and label */
    font-size: 12px; /* Adjust font size for Order ID */
    color: #333; /* Text color */
    text-align: center; /* Center text */
}

.support-header-container {
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Space between title and button */
    align-items: center; /* Center items vertically */
    margin-bottom: 16px; /* Space below the header */
}

.support-header-title {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    padding: 4px 10px;
    margin: 0;
    background-color: #fff;
    border-radius: 4px;
    text-align: left;
    transition: font-size 0.2s ease, padding 0.2s ease;
    align-items: center;
    display: inline-flex;
}

.support-add-button {
    background-color: #BA7B53;
    color: white;
    cursor: pointer;
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    padding: 0 !important;
}

.support-custom-group-content {
    display: flex;
    padding: 10px;
    background-color: #f7f7f7;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    transition: transform 0.2s ease-in-out;
}

    .support-custom-group-content:hover {
        transform: scale(1.05);
    }

    .support-custom-group-content img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 15px;
        border: 2px solid #ccc;
    }

.support-details {
    display: flex;
    flex-direction: column;
}

    .support-details .name {
        font-size: 16px;
        font-weight: bold;
        color: #333;
        margin-bottom: 5px;
    }

    .support-details .speciality {
        font-size: 14px;
        color: #666;
    }


.support-group-heading {
    font-size: 18px;
    margin-bottom: 20px; /* Adds a gap below the heading */
}

/* Define custom styles for the technician-checkbox class */
.technician-checkbox {
    width: 20px;
    height: 20px;
    cursor: pointer;
    accent-color: #1976d2; /* Customize the checkbox color */
    border: 1px solid #1976d2; /* Add a border color */
    border-radius: 4px; /* Slight rounding for a modern look */
    margin-right: 10px;
}

    /* Optional: Change appearance when the checkbox is hovered */
    .technician-checkbox:hover {
        border-color: #1565c0;
    }

    /* Optional: Change appearance when the checkbox is checked */
    .technician-checkbox:checked {
        background-color: #1976d2;
    }

.support-technicians-note {
    font-size: 11px;
    color: #333;
    background-color: #f9f9f9;
    border-left: 4px solid #007bff;
    padding: 3px;
    margin: 8px 0;
    border-radius: 4px;
    font-family: Arial, sans-serif;
}

    .support-technicians-note bold {
        font-weight: bold;
    }

.support-tech-order-circle {
    display: inline-block;
    width: 18px; /* Increased size for better visibility */
    height: 18px; /* Increased size for consistency */
    line-height: 16px; /* Adjusted line-height for center alignment */
    text-align: center;
    border-radius: 50%; /* Circular shape */
    background-color: #007bff; /* Blue background */
    color: white; /* White text color */
    font-size: 10px; /* Slightly larger font size for readability */
    font-weight: bold;
    padding: 2px; /* Adjusted padding for better spacing */
    transition: all 0.3s ease; /* Smooth transition for interactive effects */
}

    /* Hover effect */
    .support-tech-order-circle:hover {
        background-color: #0056b3; /* Darker blue on hover */
        transform: scale(1.1); /* Slight scale effect for interactivity */
    }

/* Media query for smaller screens */
@media (max-width: 600px) {
    .support-tech-order-circle {
        width: 18px; /* Smaller circle for mobile */
        height: 18px; /* Consistent size */
        font-size: 9px; /* Slightly smaller font size */
        line-height: 14px; /* Adjusted line-height */
    }
}


/* Settings container */
.settings-container {
    border: 1px solid #ccc; /* Main border */
    border-radius: 8px; /* Rounded corners */
    padding: 16px; /* Space inside the container */
    /*max-width: 380px;  Adjust width as needed */
    width: 100%;
    font-family: Arial, sans-serif;
}

/* Settings title */
.settings-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px; /* Space below the title */
    color: #fff;
}

/* Toggle container */
.toggle-container {
    display: flex;
    align-items: center;
    gap: 8px; /* Space between text and toggle */
    font-size: 12px;
}

/* Toggle label styling */
.toggle-label {
    color: #605D5D;
    min-width: 150px;
}

/* Toggle button */
.toggle-btn {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: #ccc;
    border-radius: 10px;
    cursor: pointer;
}

    /* Circle inside the toggle button */
    .toggle-btn .indicator {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 16px;
        height: 16px;
        background-color: white;
        border-radius: 50%;
        transition: 0.3s ease;
    }

    /* Active toggle button state */
    .toggle-btn.active {
        background-color: #BA7B53;
    }

        .toggle-btn.active .indicator {
            left: 22px;
        }

.smart-tooltip:not([animation=none])[visible] {
    display: none !important;
    pointer-events: none;
    opacity: 0;
}

/*.smart-scheduler-view-nav,
.smart-scheduler-date-nav {
    pointer-events: none;
    cursor: not-allowed;
}*/

[smart-id="currentDate"] {
    pointer-events: none; /* Disable all interactions (click, hover, etc.) */
    cursor: not-allowed; /* Show a "not-allowed" cursor */    
}

[smart-id="viewItemsButton"] {
    pointer-events: none; /* Disable all interactions (click, hover, etc.) */
    cursor: not-allowed; /* Show a "not-allowed" cursor */
}

/* Context Menu Styles */
#schedule-context-menu {
    position: absolute;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 6px 12px 0px #0000000F;
    border: 1px solid #E7E7E7;
    padding: 0;
    z-index: 9999;
    width: 270px;
    font-family: Arial, sans-serif;
}

    /* Blue Circle */
    #schedule-context-menu .blue-circle {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
        position: absolute;
        left: 10px;
        top: 16px;
    }

    /* Event Details */
    #schedule-context-menu .event-details {
        display: inline-block;
        width: 100%;
    }

    /* Event Name */
    #schedule-context-menu .event-name {
        padding: 9px 10px 0 23px;
        color: #000;
        font-weight: 600;
        text-align: left;
        font-size: 14px;
        display: inline-flex;
        justify-content: space-between;
        gap: 5px;
        width: 100%;
    }

    /* Order ID Circle */
    #schedule-context-menu .order-id-circle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #007bff; /* Blue background */
        color: #ffffff; /* White text */
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        font-size: 12px;
        font-weight: bold;
    }

    /* Event Time */
    #schedule-context-menu .event-time {
        font-size: 13px;
        color: #888;
        padding: 6px 10px 6px 23px;
        border-bottom: 1px solid #E7E7E7;
        padding-top: 0;
    }

    /* Delete Icon */
    #schedule-context-menu .delete-icon {
        float: right;
        cursor: pointer;
        margin-left: auto;
    }

        #schedule-context-menu .delete-icon:hover {
            background-color: #eeeeeea3;
        }

    #schedule-context-menu .delete-icon {
        padding: 7px 10px;
        background-color: transparent;
        color: #605D5D;
        font-weight: 500;
        border: none;
        border-radius: 0;
        cursor: pointer;
        font-size: 14px;
        display: inline-flex;
        width: 100%;
        margin: 0 !important;
        gap: 6px;
        align-items: center;
    }

        #schedule-context-menu .delete-icon img {
            cursor: pointer;
            height: 20px;
        }

.change-order-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    background-color: var(--bgColor, #ffffff);
    padding: 10px;
    border-radius: 5px;
    color: #ffffff;
    cursor: grab;
}

    .change-order-list-item.dragging {
        opacity: 0.5;
    }

    .change-order-list-item.dragover {
        background-color: #f1c40f;
    }

    .change-order-list-item.dragleave {
        background-color: #f4a261;
    }

.change-order-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffffff;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-weight: bold;
}

.change-order-drag-icon {
    margin-left: auto; /* Push icon to the right */
    cursor: grab;
    font-size: 1.5em;
    color: #fff; /* Adjust color as needed */
}

.event-container {
    /* max-width: 400px;*/
    /* margin: 0 auto;*/
    padding: 0px;
    width: 100%;
}

    .event-container h2 {
        text-align: center;
        color: #333;
        margin-bottom: 20px;
    }

/* .event-list {
    max-height: 300px;  
    overflow-y: auto;  
} */

.event {
    margin-bottom: 8px;
    padding: 10px;
    background: #fff;
    border: none;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    transition: background-color 0.3s, transform 0.3s;
}

    .event:hover {
        background-color: #E3F9E8;
        cursor: pointer;
    }

    .event.selected {
        background-color: #E3F9D3;
    }

.event-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.event-name {
    font-size: 14px;
    color: #000000;
    font-weight: 600;
}

.time-zone {
    font-size: 12px;
    color: #BA7B53;
}

.support-technician-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2px 5px;
}

.technician-label {
    font-size: 16px; /* Default font size for the technician label */
    color: #fff; /* Dark text color for readability */
}

.technician-time-zone {
    font-size: 11px;
    color: #fff;
}

.support-details-row.weeklyHOurs {
    margin-top: 10px;
}

.support-details-row {
    display: flex;
    align-items: center;
    margin-top: 0;
    flex-wrap: wrap;
}

.time-slot {
    font-size: 12px;
    color: #A8A8A8;
    white-space: nowrap;
    flex: 1 1;
}

.operating-hours {
    color: #605D5D;
}

.support-user-circle-container {
    display: flex;
    position: relative;
    flex-wrap: wrap; /* Wrap circles on smaller screens */
}

.support-user-circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    border: 1px solid #fff;
    margin-left: -8px;
    z-index: 1;
    line-height: 28px
}

    .support-user-circle:first-child {
        margin-left: 0; /* Ensure the first circle does not overlap */
    }

    .support-user-circle:hover {
        z-index: 10; /* Bring hovered circle to the front */
        background-color: #0056b3;
    }

.support-Cont-child {
    max-height: calc(100vh -15px);
    height: 100%;
}
/* Media Query for Small Screens */
@media (max-width: 768px) {
    .support-details-row {
        align-items: flex-start; /* Align to the left */
    }

    .support-user-circle-container {
        margin-top: 8px; /* Add spacing between text and circles */
    }

    .support-user-circle {
        margin-left: 0; /* Remove overlapping for smaller screens */
    }
}

.weekday-container {
    display: flex;
    gap: 0;
    padding: 2px 0;
    background: #BA7B53;
}

.weekday-circle {
    font-size: 12px;
    cursor: pointer;
    padding-right: 8px;
    border-right: 1px solid #FFFFFF33;
    padding-left: 8px;
}

    .weekday-circle:last-child {
        border: none;
        margin: 0;
    }

    .weekday-circle:hover {
        background: #905738;
    }

.support-details-row:last-child {
    margin-bottom: 0;
}

/* Styles for the cell custom modal */
#cell-custom-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 4px;
    z-index: 1000;
    text-align: center;
    min-width: 260px;
    max-width: 260px;
    box-shadow: 0px 6px 12px 0px #0000000F;
    border: 1px solid #E7E7E7;
    padding: 0;
}

    #cell-custom-modal button {
        padding: 7px 10px;
        background-color: transparent;
        color: #605D5D;
        font-weight: 500;
        border: none;
        border-radius: 0;
        cursor: pointer;
        font-size: 14px;
        display: inline-flex;
        width: 100%;
        margin: 0 !important;
        gap: 6px;
        align-items: center;
    }

        #cell-custom-modal button img {
            height: 22px;
        }

.date-userName {
    padding: 12px 10px 10px;
    color: #fff;
    background-color: #BA7B55;
    font-weight: 600;
    text-align: left;
    font-size: 14px;
    border-bottom: 1px solid #E7E7E7;
}

#cell-custom-modal button:hover {
    background-color: #eeeeeea3;
}

#cell-custom-modal .modal-content {
    border: none;
}

.scheduler-wrapper {
    overflow-x: auto;
    /*height: 600px;*/
    height: calc(100vh - 190px);
}

.scheduler {
    display: grid;
    width: fit-content;
}

.time-column {
    background-color: #444;
    text-align: right;
    padding-right: 5px;
}

.tech-time-slot {
    height: 36px;
    border-bottom: 1px solid #666;
    line-height: 20px;
}

.technician-column {
    border-left: 1px solid #666;
    position: relative;
    background-color: #444444;
}

.column-header {
    height: 46px;
    /* background-color: #222; */
    text-align: center;
    /* line-height: 30px; */
    font-weight: bold;
    border-bottom: 1px solid #666;
    overflow: hidden;
    font-size: 15px;
}

/*.apexcharts-xaxis text {
    transform: rotate(0deg) !important;  
    writing-mode: vertical-lr !important; 
    text-align: center !important; 
    font-size: 12px;  
    color: #333;  
}*/

.task {
    position: absolute;
    width: 90%;
    margin: 0 auto;
    left: 5%;
    border-radius: 5px;
    text-align: center;
    color: black;
    font-size: 12px;
    font-weight: bold;
    cursor: grab;
    line-height: 50px;
}

.dragging {
    opacity: 0.7;
    cursor: grabbing;
}

.smart-scheduler-time-zone::before {
    content: "GMT"; /* Text to display */
    display: inline-block; /* Ensures it behaves like a visible block/inline */
    margin-right: 4px; /* Adds spacing between the text and the rest of the element */
    color: #000; /* Adjust text color as needed */
    font-size: 14px; /* Adjust font size as needed */
}

/* Modal background overlay */
.view-report-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Darker background overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears on top */
    animation: fadeIn 0.3s ease-out; /* Smooth fade-in transition */
}

/* Modal content box */
.view-report-modal-content {
    background-color: #fff;
    padding: 0px 30px 30px;
    border-radius: 10px;
    width: 90%; /* Adjust width */
    max-height: 80%; /* Ensure it fits within the screen */
    overflow-y: auto; /* Scrollable content if it exceeds max height */
    position: relative;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    transform: translateY(-50px); /* Initial position for animation */
    animation: slideUp 0.5s ease-out forwards; /* Slide-up animation */
}

/* Close button style */
.view-report-close {
    position: absolute;
    top: 24px;
    right: 15px;
    font-size: 35px;
    cursor: pointer;
    color: #333;
    transition: color 0.3s ease;
}

    .view-report-close:hover {
        color: #e74c3c; /* Change color on hover */
    }

/* Modal content text styling */
.view-report-content {
    color: #333;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    margin-top: 20px;
}



/* Modal title styling */
h2 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    /*margin-bottom: 20px;*/
}

/* Smooth fade-in for modal background */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Slide-up animation for modal content */
@keyframes slideUp {
    from {
        transform: translateY(50px); /* Start from 50px below */
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.equalizer-icon {
    color: #fff;
    height: 30px !important;
    width: 30px !important;
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
}

.btn-box {
    padding: 10px;
    display: flex;
    gap: 10px;
}

.days-weeks {
    padding: 3px 12px;
    font-size: 14px;
    color: #000;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.days-selected {
    background-color: #BA7B53;
    color: white;
    border: none;
}

.InputBox {
    display: flex;
    gap: 7px;
    color: #000;
    font-size: 14px;
    padding: 10px 10px 15px;
    border-bottom: 1px solid #E7E7E7;
    align-items: center;
}

input.days-input {
    width: 100px;
    border: 1px solid #ccc;
    padding: 3px 10px;
    border-radius: 4px;
}

.apply-button {
    padding: 4px 12px;
    font-size: 14px;
    color: #fff;
    background-color: #BA7B53;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-header {
    display: flex;
    justify-content: space-between; /* Aligns the title and button to opposite sides */
    align-items: center;
}

.right-button {
    background-color: #585858;
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    margin-right: 35px;
}

.even-row {
    background-color: #f9f9f9; /* Light gray for even rows */
}

.odd-row {
    background-color: #ffffff; /* White for odd rows */
}

@media screen and (max-width:1200px) {
    .scheduler-wrapper {
        height: auto;
    }
}

.shopping-bagIcon {
    color: #BA7B53;
    padding: 2px;
}

.selected-site {
    padding: 10px;
}

.addtech-btn {
    border-radius: 30px;
    padding: 2px 11px;
    background-color: #B97A57;
    border-color: #B97A57;
    min-height: 36px;
    margin-right: 20px;
    margin-bottom: 4px !important;
}

.coverage-modal-title {
    color: #333;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    border: 1px solid #312c2c;
    border-radius: 4px;
    padding: 4px;
    font-weight: 700;
}

.no-data-tech {
    background: rgba(255, 255, 255, 0.11); /* Slightly transparent white */
    border-radius: 20px; /* Rounded corners */
    height: 300px; /* Fixed height */
    margin: 0 auto; /* Center horizontally */
    padding: 120px 0; /* Vertical padding */
    display: flex; /* Flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    text-align: center; /* Center text */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    font-size: 1.2rem; /* Slightly larger font size */
    color: #ffffff; /* White text */
    font-family: "Arial", sans-serif; /* Clean font */
}

.tech-custom-tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 15); /* Dark background */
    color: #fff; /* White text */
    padding: 6px 12px; /* Padding for the tooltip content */
    border-radius: 4px; /* Rounded corners */
    font-size: 12px; /* Small font size */
    white-space: nowrap; /* Prevent text wrapping */
    pointer-events: none; /* Tooltip doesn't block mouse events */
    z-index: 1000; /* Ensure it appears on top */
    transform: translateY(-100%); /* Adjust vertical position */
    transition: opacity 0.2s ease-in-out; /* Smooth fade effect */    
    cursor: grab;
}

.task-info {
    font-family: Arial, sans-serif; /* Ensure readable font */
}

    .task-info .timezone {
        font-weight: bold;
        margin-right: 10px; /* Add spacing from the time range */
    }

    .task-info .time-range {
        display: inline-block; /* Ensure it stays on one line */
        margin: 0;
        font-size: 8px; /* Adjust the base font size */
    }

    .task-info .time {
        font-size: 12px; /* Smaller font for the time values */
        font-weight: normal; /* Regular weight for time values */
    }

.hover-div {
    align-items: flex-start;
    background: #585858;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 300px;
    justify-content: flex-start;
    left: 0;
    overflow-y: auto;
    padding: 10px;
    position: absolute;
    scrollbar-color: #ccc #585858;
    scrollbar-width: thin;
    text-align: left;
    top: 40px;
    width: 300px;
    z-index: 1000;
    font-size: 12px;
}

/* Styling the modal header */
.hover-div-header {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    color: #FFD700; /* Gold text for emphasis */
    border-bottom: 1px solid #ccc; /* Optional separator line */
    padding-bottom: 5px;
}

    /* Styling the scrollbar for Webkit browsers */
    .hover-div::-webkit-scrollbar {
        width: 8px; /* Scrollbar width */
    }

    .hover-div::-webkit-scrollbar-track {
        background: #BA7B53; /* Scrollbar track background */
        border-radius: 4px;
    }

    .hover-div::-webkit-scrollbar-thumb {
        background: #ccc; /* Scrollbar thumb color */
        border-radius: 4px;
    }

        .hover-div::-webkit-scrollbar-thumb:hover {
            background: #fff; /* Scrollbar thumb hover color */
        }

.maximize-button {
    top: 10px;
    right: 10px;
    background-color: #BA7B53;
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
    margin-right: 8px;
}

.attention-circle {
    display: flex;
    justify-content: center; /* Centers the icon horizontally */
    align-items: center; /* Centers the icon vertically */
    width: 30px; /* Adjust the size of the circle */
    height: 30px;
    border-radius: 50%; /* Makes it a circle */
    background-color: #BA7B53; /* Circle background color */
    cursor: pointer;
}

.i-icon {
    color: white; /* Icon color */
    font-size: 20px !important; /* Icon size */
    cursor: pointer;
    height: 25px; /* Icon height */
    width: 25px; /* Icon width */
}

.add-call-icon {
    color: #FBAF5D;
    margin-left: 5px;
    cursor: pointer;
    font-size: small;
}